
import React, { lazy, Suspense } from "react";
import { AsideMenu, AsideMenuProvider } from "../../Component/Aside/AsideMenuProvider";
import { Brand, BrandProvider } from "../../Component/Wrapper/Header/BrandProvider";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Aside from "../../Component/Aside/Aside";
import Wrapper from "../../Component/Wrapper/Wrapper";
import { logOutUser } from "../../Utility/helpers";

const View = ({ children }) => (
    <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-h-400px"><i className="fas fa-spinner fa-spin fs-3x"></i></div>}>
        {children}
    </Suspense>
);

const Dashboard = lazy(() => import("./pages/Dashboard"));
const System = lazy(() => import("./pages/systems/Base"));
const Company = lazy(() => import("./pages/Company"));
const Department = lazy(() => import("./pages/Department"));
const Position = lazy(() => import("./pages/Position"));
const User = lazy(() => import("./pages/users/Base"));

function WithUseCases() {
    const navigate = useNavigate();

    return (
        <AdminWrapper
            navigate={navigate}
        />
    )
}

class AdminWrapper extends React.Component {

    system = {
        title: 'Admin',
        icon: 'fs-4 fas fa-user-edit',
        href: '/system/admin',
    };

    menus = [
        {
            title: 'Dashboard',
            icon: 'fas fa-layer-group ms-1',
            href: '/admin/dashboard'
        },
        {
            title: 'Systems',
            icon: 'fas fa-inbox',
            href: '/admin/systems'
        },
        {
            title: 'Companies',
            icon: 'fas fa-file-contract ms-1',
            href: '/admin/companies'
        },
        {
            title: 'Departments',
            icon: 'fas fa-file-signature ms-1',
            href: '/admin/departments'
        },
        {
            title: 'Positions',
            icon: 'fas fa-cogs',
            href: '/admin/positions'
        },
        {
            title: 'Users',
            icon: 'fas fa-file-contract ms-1',
            href: '/admin/users'
        }
    ];

    constructor() {
        super();
        this.state = {
            user: null,
            systems: []
        }
    }

    componentDidMount() {
        this.setState(() => {
            try {
                const hasUserStorage = localStorage.getItem("user");
                if (hasUserStorage) {
                    const user = JSON.parse(hasUserStorage);

                    const hasSystemAccesses = localStorage.getItem("systems");
                    if (hasSystemAccesses) {
                        
                        return {
                            user: user,
                            systems: JSON.parse(hasSystemAccesses) || []
                        };
                    }

                    return {
                        user: user,
                        systems: []
                    };
                }

                return {
                    user: null
                };
            } catch(error) {
                return {
                    user: null,
                    systems: []
                }
            }
        }, () => {
            const hasSystemAccess = this.state.systems.length > 0 && this.state.systems.filter(
                system => this.menus.map(menu => menu.href).includes(system.href)
            ).length <= 0;

            if (hasSystemAccess) {
                this.props.navigate('/');
            }

            if (!this.state.user) {
                logOutUser();
            }
        });
    }

    render() {
        if (this.state.user) {
            return (
                <AsideMenuProvider>
                    <AsideMenu
                        lists={this.menus}
                    />
                    <BrandProvider>
                        <Brand
                            mobile={this.system.title}
                            desktop={this.system.title}
                            icon={this.system.icon}
                        />
                        <BreadCrumbsProvider>
                            <Routes>
                                <Route element={
                                    <>
                                        <Aside footer={this.state.user} />
                                        <Wrapper />
                                    </>
                                }>
                                    <Route
                                        path="dashboard"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Dashboard", href: `#` }
                                                    ]}>
                                                    Dashboard
                                                </BreadCrumbs>
                                                <Dashboard user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="systems"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Systems", href: `#` }
                                                    ]}>
                                                    Systems
                                                </BreadCrumbs>
                                                <System user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="companies"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Companies", href: `#` }
                                                    ]}>
                                                    Companies
                                                </BreadCrumbs>
                                                <Company user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="departments"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Departments", href: `#` }
                                                    ]}>
                                                    Departments
                                                </BreadCrumbs>
                                                <Department user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="positions"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Positions", href: `#` }
                                                    ]}>
                                                    Positions
                                                </BreadCrumbs>
                                                <Position user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="users"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Users", href: `#` }
                                                    ]}>
                                                    Users
                                                </BreadCrumbs>
                                                <User user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        index
                                        path="*"
                                        element={
                                            <Navigate to="dashboard" />
                                        }
                                    />
                                </Route>
                            </Routes>
                        </BreadCrumbsProvider>
                    </BrandProvider>
                </AsideMenuProvider>
            );
        }

        return <></>;
    }
}

export default WithUseCases;