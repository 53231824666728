import { useState } from "react";
import { Button, Card, Col, Image } from "react-bootstrap";

import Ajax from "../../../../Utility/Ajax";
import { Alert, userProfilePath } from "../../../../Utility/Plugins";
import parse from "html-react-parser";

import FormatDate from "moment";

const PostContentFormat = ({ content }) => (
	<div className="ql-editor">{parse(content)}</div>
);

export default function PostFormatDashboard({
	user,
	post,
	setStatus,
	status = true,
	forum,
	onSelect,
}) {
	const [isSubmitting, setSubmitting] = useState(false);

	const onCloseOpen = (id, post_status = "CLOSED") => {
		Ajax.post(
			"/forum-v2/post/update/close-open",
			{
				id: id,
				post_status: post_status,
			},
			() => {
				setSubmitting(true);
				Alert("UPDATING", "PLEASE WAIT", "info");
			},
			(result) => {
				switch (result.response) {
					case "updated": {
						Alert("UPDATED", "YOUR POST HAS BEEN UPDATED", "success", () => {
							setStatus(post_status);
						});
						break;
					}
					default: {
						Alert(
							"OOPS",
							"YOUR POST IS NOT UPDATED, PLEASE TRY AGAIN",
							"warning",
							true
						);
						break;
					}
				}
				setSubmitting(false);
			},
			() => {
				Alert("OOPS", "SOMETHING WENT WRONG", "error", () => {
					setSubmitting(false);
				});
			}
		);
	};

	const ChangeButtonByStatus = (post_status) => {
		return post_status === "OPEN" ? (
			<>
				<i className="fs-3 fas fa-comment"></i>
				Comment
			</>
		) : (
			<>
				<i className="fs-3 fas fa-eye"></i>
				View
			</>
		);
	};

	return (
		<Col xs={12} className="mb-5 px-0">
			<Card className="border border-1 border-gray-300 rounded-0">
				<Card.Header className="px-5 pt-5 border-0">
					<div className="col-12 d-flex flex-md-row flex-column justify-content-between">
						<div className="d-flex align-self-md-center">
							<Image
								width={50}
								height={50}
								roundedCircle
								src={userProfilePath(post.created_by)}
								onError={(e) =>
									(e.target.src = "/assets/media/avatars/blank.png")
								}
							/>
							<div className="ms-3">
								<div
									className="fs-5 fw-bolder cursor-pointer"
									onClick={() =>
										!isSubmitting &&
										onSelect(forum, () => {
											window.location.href = `/system/forum-v2/profile/${post.created_by}`;
										})
									}
								>
									{post.first_name} {post.middle_name} {post.last_name}{" "}
									{post.suffix_name}
								</div>
								<div className="fs-7 text-gray-500">
									{FormatDate(post.created_at)
										.format("MMMM DD, YYYY @ LT")
										.replace("@", "at")}
								</div>
							</div>
						</div>
						<div className="d-flex align-self-end align-self-md-center">
							{post.post_status === "CLOSED" && (
								<div className="badge badge-light-danger border border-danger fw-bolder fs-8 text-capitalize">
									{post.post_status}
								</div>
							)}
						</div>
					</div>
				</Card.Header>
				<Card.Body className="d-flex flex-column py-0 px-0">
					<div className="col-12 p-3 border-bottom border-gray-300 border-1">
						<div className="fs-5 fw-bolder">{post.post_title}</div>
						<div className="fs-6">
							Reference:
							{Boolean(Number(post.is_original)) ? (
								"Original Content"
							) : (
								<a href={post.post_reference} target="view-reference">
									{post.post_reference?.substring(0, 120)}...
								</a>
							)}
						</div>
					</div>
					{post?.post_content && (
						<PostContentFormat content={post.post_content} />
					)}
					<div className="d-flex flex-md-row justify-content-between p-3 border-top border-gray-300 border-1">
						<div className="d-flex">
							<Button
								onClick={() =>
									onSelect(forum, () => {
										window.location.href = `/system/forum-v2/channel/${post.channel_code}/`;
									})
								}
								size="sm"
								variant="link"
								className="p-0 px-3 text-uppercase fw-bolder"
								disabled={isSubmitting}
							>
								{post.channel_title}
							</Button>
						</div>
						<div className="d-flex align-self-end">
							<div className="p-0 px-3">
								{post.total_votes > 1
									? `${post.total_votes} Votes`
									: `${post.total_votes} Vote`}
							</div>
							<div className="p-0 px-3">
								{post.total_comments > 1
									? `${post.total_comments} Comments`
									: `${post.total_comments} Comment`}
							</div>
							<div className="p-0 px-3">
								{post.total_views > 1
									? `${post.total_views} Views`
									: `${post.total_views} View`}
							</div>
						</div>
					</div>
				</Card.Body>
				<Card.Footer className="py-3 px-3 border-0">
					<div className="d-flex justify-content-between">
						<div>
							{status && (
								<Button
									size="sm"
									variant="light-secondary"
									className="border border-secondary bg-hover-warning text-hover-white"
									disabled={isSubmitting}
									onClick={() =>
										onSelect(forum, () => {
											window.location.href = `/system/forum-v2/${post.channel_code}/${post.post_code}`;
										})
									}
								>
									{ChangeButtonByStatus(post.post_status)}
								</Button>
							)}
						</div>
						<div>
							{user.code === post.created_by && (
								<Button
									size="sm"
									variant="light-secondary"
									className="border border-secondary bg-hover-primary text-hover-white me-2"
									disabled={isSubmitting}
									onClick={() =>
										onSelect(forum, () => {
											window.location.href = `/system/forum-v2/post/${post.post_type.toLowerCase()}/${
												post.channel_code
											}/${post.post_code}`;
										})
									}
								>
									<i className="fs-3 fas fa-pen"></i>
									Edit
								</Button>
							)}
							{user.code === post.created_by &&
								(post.post_status === "OPEN" ? (
									<Button
										size="sm"
										variant="light-secondary"
										className="border border-secondary bg-hover-danger text-hover-white"
										disabled={isSubmitting}
										onClick={() => onCloseOpen(post.id)}
									>
										<i className="fs-3 fas fa-door-closed"></i>
										Close
									</Button>
								) : (
									<Button
										size="sm"
										variant="light-secondary"
										className="border border-secondary bg-hover-primary text-hover-white"
										disabled={isSubmitting}
										onClick={() => onCloseOpen(post.id, "OPEN")}
									>
										<i className="fs-3 fas fa-door-open"></i>
										Open
									</Button>
								))}
						</div>
					</div>
				</Card.Footer>
			</Card>
		</Col>
	);
}
