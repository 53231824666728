import { useLocation } from "react-router-dom";
import Single from "./Single";

const routeHelper = (href, location) => location.pathname.split(`/system${href}`).length > 1 ? "show" : ""

export default function Multiple ({lists = []}) {
    const location = useLocation();

    return (
        lists.map((item, i) => {
            return (
                typeof(item.lists) === "undefined" 
                ?
                    <Single list={item} key={i}/>
                :
                    <div to={item.href} className={`menu-item menu-accordion ${routeHelper(item.href, location)}`} data-kt-menu-trigger="click" key={i}> 
                        <span className="menu-link">
                            <span className="fs-2 menu-icon">
                                <span className={`text-white ${item?.icon}`}></span>
                            </span> 
                            <span className="fs-4 menu-title px-2 text-white">{item.title}</span> 
                            <span className="menu-arrow"></span> 
                        </span>
                        <div className="menu-sub menu-sub-accordion menu-active-bg">
                            <Multiple lists={item.lists} key={i+1}/>
                        </div>
                    </div>
            );
        })
    );
}