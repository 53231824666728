import React from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import Swal from "sweetalert2";
import CreateDate from "moment";

import Alert from "../../../../Utility/sweet-alert";
import { TableState } from "../../../../Utility/Plugins";
import Ajax from "../../../../Utility/Ajax";
import FileViewer from "./FileViewer";
import { produce } from "immer";
import { AttachmentService } from "../../services/attachment.service";

validate.addMethod(
	validate.MixedSchema,
	"hasFiles",
	function (placeholder = "Please attach some files") {
		return this.test(
			"has-files",
			placeholder,
			(files) => files?.length && files.length > 0
		).required();
	}
);

validate.addMethod(
	validate.MixedSchema,
	"isTotalFilesExceed",
	function (max = 10) {
		return this.test(
			"is-total-files-exceed",
			`Total count of attached files must not exceed ${max}`,
			(files) => files?.length && files.length > 0 && files.length < max
		);
	}
);

validate.addMethod(
	validate.MixedSchema,
	"isTotalFilesSizeExceed",
	function (max = 10) {
		return this.test(
			"is-total-files-size-exceed",
			`Total size of attach files must not exceed ${max} MB`,
			(files) => (
				files?.length && files.length > 0 && Array.from(files).map(
					(file) => file.size
				).reduce(
					(fileSize, totalSize) => Number(fileSize) + Number(totalSize)
				) / 1000000 <= max
			)
		);
	}
);

const attachmentValidation = validate.object({
	id: validate.string().required(),
	target_date: validate.string().required(),
	files: validate.mixed()
		.hasFiles()
		.isTotalFilesExceed()
		.isTotalFilesSizeExceed(Ajax.env.WORK_APPROVAL.UPLOAD.MAX_TOTAL_SIZE)
		.required(),
});

function WithUseCases({ workApproval, setFiles }) {
	
	const attachmentForm = useForm({
		resolver: yupResolver(attachmentValidation),
		defaultValues: {
			id: workApproval.id,
			target_date: workApproval.target_date
		}
	});

	return (
		<AttachmentContainer
			workApproval={workApproval}
			form={attachmentForm}
			setFiles={setFiles}
		/>
	);
}

class AttachmentContainer extends React.Component {

	constructor() {
		super();
		this.swal = new Alert();
		this.state = {
			busy: false,
			modalInvalidFileOpen: false,
			modalPreviewOpen: false,
			selectedFile: 0,
			files: [],
			invalidFiles: []
		}
	}

	componentDidMount() {
		this.provider();
	}

	getFileUri(folderId, fileName) {
		const year = CreateDate(this.props.workApproval.target_date).format("YYYY");
		const month = CreateDate(this.props.workApproval.target_date).format("MMM").toUpperCase();

		return (
			`${Ajax.env.API_URI}/work-approval/${year}/${month}/${this.props.workApproval.target_date}/${folderId}/${fileName}`
		);
	}

	format(file, key) {
		return (
			<tr key={key}>
				<td className="fs-5 pb-5">
					<div className="row">
						<div className="col-12 pb-2">
							{key + 1}) {file.name.substring(0, 30)}
						</div>
						<div>
							<div className="d-flex justify-content-end">
								<Button
									size="sm"
									variant="warning"
									disabled={this.state.busy}
									onClick={() => this.setState({
										modalPreviewOpen: true,
										selectedFile: key
									})}
								>
									View
								</Button>
								<Button
									download
									size="sm"
									className="ms-1"
									variant="secondary"
									target="download-file"
									disabled={this.state.busy}
									href={this.getFileUri(file.folder_id, file.name)}
								>
									Download
								</Button>
								{
									Number(this.props.workApproval.mail_sent) === 0 && (
										<Button
											size="sm"
											variant="danger"
											className="ms-1"
											disabled={this.state.busy}
											onClick={
												() => this.onDelete(file)
											}
										>
											Remove
										</Button>
									)
								}
							</div>
						</div>
					</div>
				</td>
			</tr>
		)
	}

	render() {
		return (
			<Col xs={12} className="pb-5">
				<Container fluid className="px-0">
					<Card className="border-2 border-gray-300 rounded-0">
						<Card.Header className="border-2 border-gray-300 border-gray-300">
							<Card.Title>Attachments:</Card.Title>
						</Card.Header>
						<Card.Body>
							<div
								className="px-3"
								style={{
									maxHeight: "300px",
									minHeight: "300px",
									overflowY: "auto",
								}}
							>
								<Table>
									<tbody>
										{
											this.state.files.length <= 0 || this.state.busy ? TableState(
												1,
												this.state.busy,
												this.state.busy ? 'GATHERING ATTACHMENTS' : ' - - NO ATTACHMENT - - '
											) : this.state.files.map(
												(file, i) => this.format(file, i)
											)
										}
									</tbody>
								</Table>
							</div>
						</Card.Body>
						{
							Number(this.props.workApproval.mail_sent) === 0 && (
								<Card.Footer className="p-3 border-2 border-gray-300">
									<Form
										noValidate
										onSubmit={
											this.props.form.handleSubmit(
												(validated) => this.onSubmit(validated)
											)
										}
										className="row"
									>
										<Form.Group className="col-12">
											<InputGroup>
												<Form.Control
													{...this.props.form.register("files")}
													size="sm"
													type="file"
													multiple
													className=" border-gray-300"
													readOnly={this.state.busy}
												/>
												<Button
													size="sm"
													variant="primary"
													type="submit"
													disabled={this.state.busy}
												>
													Upload
												</Button>
											</InputGroup>
											<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
												{this.props.form.formState.errors.files?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Form>
								</Card.Footer>
							)
						}
					</Card>

					<Modal
						show={this.state.modalInvalidFileOpen}
						size="lg"
						centered
						backdrop={false}
						keyboard={false}
						fullscreen="sm-down"
						className="bg-gray-200"
					>
						<Modal.Header>
							<Modal.Title>Invalid Files</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Table
								striped
								responsive
								className="table-relative align-middle table-row-dashed"
							>
								<thead>
									<tr>
										<th>Name</th>
										<th>Type</th>
										<th>Size</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.invalidFiles.map((file) => (
											<tr>
												<td>{file.name}</td>
												<td>{file.type}</td>
												<td>{parseFloat(file.size).toFixed(3)} MB</td>
												<td class="text-danger">{file.error}</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						</Modal.Body>
						<Modal.Footer className="d-flex justify-content-end">
							<Button
								size="sm"
								variant="outline-dark"
								onClick={
									() => this.setState({
										modalInvalidFileOpen: false
									})
								}
							>
								Close
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal
						show={this.state.modalPreviewOpen}
						fullscreen={true}
						backdrop={false}
						keyboard={false}
					>
						<Modal.Body className="d-flex justify-content-center">
							{
								this.state.files.length > 0 && (
									<FileViewer
										url={
											this.state.files.map((row) =>
												produce(row, (current) => {
													current.uri = this.getFileUri(current.folder_id, current.name)
													return current;
												})
											)
										}
										selected={this.state.selectedFile}
									/>
								)
							}
						</Modal.Body>
						<Modal.Footer className="d-flex justify-content-end">
							<Button
								size="sm"
								variant="outline-dark"
								onClick={
									() => this.setState({
										modalPreviewOpen: false
									})
								}
							>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				</Container>
			</Col>
		);
	}

	provider() {
		this.setState({
			busy: true,
			files: []
		}, () => {
			AttachmentService.get({
				id: this.props.workApproval.id,
				target_date: this.props.workApproval.target_date
			}).then((response) => {
				this.setState({
					files: Object.keys(response.files).map(
						file => response.files[file]
					)
				}, () => {
					this.props.setFiles(this.state.files)
				});
			}).finally(() => {
				this.setState({
					busy: false
				});
			});
		});
	}

	onSubmit(workApproval) {
		this.setState({
			busy: true
		}, () => {

			const formData = new FormData();
			formData.append('id', workApproval.id);
			formData.append('target_date', workApproval.target_date);

			workApproval.files.forEach((file, key) => {
				formData.append(`files[${key}]`, file, file.name);
			});
			this.swal.loader('Uploading files 0.00 / 100%', 'Please Wait');

			AttachmentService.post(formData, (_progress, loaded, total) => {
				Swal.update({
					html: `<div>
						<p>Uploading files</p>
						<p>${parseFloat((loaded / total) * 100).toFixed(2)} / 100.00 %</p>
					<div>`,
				});
			}).then(({ message }) => {
				switch (message) {
					case "uploaded": {
						this.provider();
						this.swal.success('Files has been uploaded');
						this.props.form.reset();
						break;
					}
					default: {
						this.swal.success('Some files are not uploaded', 'Notice').then(() => {
							this.setState({
								modalInvalidFileOpen: true
							}, () => {
								this.provider();
							});
						});
						break;
					}
				}
			}).finally(() => {
				this.setState({
					busy: false
				})
			})
		});
	}

	onDelete = (file) => {
		this.setState({
			busy: true
		}, () => {
			AttachmentService.delete({
				id: this.props.workApproval.id,
				target_date: this.props.workApproval.target_date,
				file: file.name
			}).then(({ message }) => {
				switch (message) {
					case "deleted": {
						this.provider();
						this.swal.success('Files has been deleted');
						break;
					}
					default: {
						this.swal.success('Unable to delete file', 'Notice');
						break;
					}
				}
			}).finally(() => {
				this.setState({
					busy: false
				});
			});
		});
	}

}


export default WithUseCases