import { useEffect } from "react";

export default function ContentContainer({ children }) {

    useEffect(() => {
        window.KTDrawer.init();
        window.KTMenu.init();
        window.KTScroll.init();
    }, [])

    return (
        <div className="container-fluid rounded min-h-450px order-2">
            <div className="container-xxl min-h-450px px-0">{children}</div>
        </div>
    );
}