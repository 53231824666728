import { Plugin } from 'ckeditor5';
import { getTranslation } from './utils/common-translations.js';

import {PaginationLooker} from './pagination-lookup.js';
import {PaginationRenderer} from './pagination-renderer.js';


export class PaginationEditing extends Plugin {
    static get pluginName() {
        return 'PaginationEditing';
    }

    static get requires() {
        return [PaginationLooker, PaginationRenderer];
    }

    init() {
        this.set({
            pageNumber: 1,
            totalPages: 1
        });

        const paginationPlugin = this.editor.plugins.get('Pagination');
        const paginationLookup = this.editor.plugins.get(PaginationLooker);
        const paginationRenderer = this.editor.plugins.get(PaginationRenderer);

        this.bind('isEnabled').to(paginationPlugin);
        this.bind('pageNumber').to(paginationRenderer);

        this._setUpDataDowncastConversion();

        this.listenTo(paginationLookup, 'change:pageBreaks', (evt, oldValue, newValue, batch) => {
            this.totalPages = newValue.length;
            this._updatePageBreakMarkers(newValue, batch);
        });

        this._setupKeystrokes();
    }

    scrollToPage(pageNumber) {
        if (!this.isEnabled) return;

        const validatedPageNumber = this._validatePageNumber(pageNumber);
        this.editor.plugins.get(PaginationRenderer).scrollToPage(validatedPageNumber);
    }

    moveSelectionToPage(pageNumber) {
        if (!this.isEnabled) return;

        const editor = this.editor;
        const pageBreakInfo = editor.plugins.get(PaginationLooker).pageBreaks[pageNumber - 1];

        if (pageBreakInfo) {
            editor.model.change(writer => {
                switch (pageBreakInfo.type) {
                    case 'manual': {
                        const selectionRange = editor.model.schema.getNearestSelectionRange(pageBreakInfo.modelRange.end, 'forward');
                        writer.setSelection(selectionRange);
                        break;
                    }
                    case 'element': {
                        const selectionRange = editor.model.schema.getNearestSelectionRange(pageBreakInfo.modelRange.start, 'forward');
                        writer.setSelection(selectionRange);
                        break;
                    }
                    case 'text': {
                        const start = pageBreakInfo.modelRange.start;
                        if (start.textNode?.data[start.offset] === ' ') {
                            const position = writer.createPositionAt(start.parent, start.offset + 1);
                            writer.setSelection(position);
                        } else {
                            writer.setSelection(pageBreakInfo.modelRange);
                        }
                        break;
                    }
                    default: {
                        
                        break;
                    }
                }

                editor.editing.view.focus();
            });
        }
    }

    goToNextPage() {
        const nextPage = this._validatePageNumber(this.pageNumber + 1);
        this.moveSelectionToPage(nextPage);
        this.scrollToPage(nextPage);
    }

    goToPreviousPage() {
        const previousPage = this._validatePageNumber(this.pageNumber - 1);
        this.moveSelectionToPage(previousPage);
        this.scrollToPage(previousPage);
    }

    _updatePageBreakMarkers(newPageBreaks, oldPageBreaks) {
        const model = this.editor.model;

        if (this.isEnabled) {
            model.change(writer => {
                newPageBreaks.forEach((pageBreak, index) => {
                    const markerName = `pagination:${index + 1}`;
                    if (model.markers.has(markerName)) {
                        writer.updateMarker(markerName, { range: pageBreak.modelRange });
                    } else {
                        writer.addMarker(markerName, {
                            range: pageBreak.modelRange,
                            usingOperation: false,
                            affectsData: true
                        });
                    }
                });

                // Remove excess markers if page count has decreased.
                for (let i = newPageBreaks.length; i < oldPageBreaks.length; i++) {
                    const markerName = `pagination:${i + 1}`;
                    if (model.markers.has(markerName)) {
                        writer.removeMarker(markerName);
                    }
                }
            });
        }
    }

    _setUpDataDowncastConversion() {
        const paginationMarker = 'pagination';

        this.editor.conversion.for('dataDowncast').add(dispatcher => {
            
            dispatcher.on(`addMarker:${paginationMarker}`, (evt, data, conversionApi) => {
              
                if (!conversionApi.options.pagination) return;
                if (!conversionApi.consumable.consume(data.markerRange, evt.name)) return;

                let viewElement = data.markerRange.getContainedElement()
                    ? conversionApi.mapper.toViewElement(data.markerRange.getContainedElement())
                    : null;

                if (!viewElement) {
                    const start = data.markerRange.start;
                    if (conversionApi.schema.checkChild(start, '$text')) {
                        const viewPosition = conversionApi.mapper.toViewPosition(start);
                        viewElement = conversionApi.writer.createContainerElement('span');
                        viewElement.getFillerOffset = () => null;
                        conversionApi.writer.insert(viewPosition, viewElement);
                    } else {
                        viewElement = conversionApi.mapper.toViewElement(start.nodeAfter);
                    }
                }

                while (viewElement.parent !== viewElement.root && viewElement.index === 0) {
                    viewElement = viewElement.parent;
                }

                const pageIndex = data.markerName.substring(11);
                if (!viewElement.hasClass('page-break')) {
                    conversionApi.writer.setStyle('page-break-before', 'always', viewElement);
                    conversionApi.writer.setAttribute('data-pagination-page', pageIndex, viewElement);
                    conversionApi.mapper.bindElementToMarker(viewElement, data.markerName);
                }
                evt.stop();
            });
        });
    }

    _setupKeystrokes() {
        const editor = this.editor;

        editor.keystrokes.set('Shift+PageUp', (evt, cancel) => {
            this.goToPreviousPage();
            cancel();
        });

        editor.keystrokes.set('Shift+PageDown', (evt, cancel) => {
            this.goToNextPage();
            cancel();
        });

        editor.accessibility.addKeystrokeInfoGroup({
            id: 'pagination',
            label: getTranslation(editor.locale, 'Keystrokes for navigating through documents'),
            keystrokes: [
                {
                    label: getTranslation(editor.locale, 'Go to the previous page (also move selection)'),
                    keystroke: 'Shift+PageUp',
                    mayRequireFn: true
                },
                {
                    label: getTranslation(editor.locale, 'Go to the next page (also move selection)'),
                    keystroke: 'Shift+PageDown',
                    mayRequireFn: true
                }
            ]
        });
    }

    _validatePageNumber(pageNumber) {
        if (pageNumber < 1) return 1;
        if (pageNumber > this.totalPages) return this.totalPages;
        return pageNumber;
    }
}
