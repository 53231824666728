import { useAsideMenu } from "./AsideMenuProvider";
import Multiple from "./BodyContent/Multiple";
import Single from "./BodyContent/Single";

export default function AsideMenu() {
    const { lists } = useAsideMenu();

    return (
        lists.length <= 0
            ?
            <Single list={{
                href: "#",
                title: "No Page Access Available",
                icon: "fas fa-times-circle"
            }} key={0} />
            :
            <Multiple lists={lists} key={0} />
    );
}