
export const canNavigate = (pages, location) => pages.findIndex((page) => {
    if (page.lists) {
        return page.lists.findIndex((pl) => `/system${pl.href}` === location.pathname) >= 0;
    } else {
        return `/system${page.href}` === location.pathname
    }
}) >= 0;

export const logOutUser = () => {
    try {
        const userItem = localStorage.getItem("user");

        if (userItem) {
            const user = JSON.parse(userItem);
            const userDefaults = localStorage.getItem(user.code);

            localStorage.clear();
            
            if (localStorage.length <= 0) {
                localStorage.setItem(user.code, userDefaults);
                window.location.href = "/";
            }
        } else {
            window.location.href = "/";
        }
    } catch (error) {
        window.location.href = "/";
    }
}