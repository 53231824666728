import axios from "axios"
import { objectToUrl } from "../../../Utility/Plugins";

const SettingService = {
    get(payload = {}) {
        return axios.get(`work-approval/settings?${objectToUrl(payload)}`)
    },

    post(payload) {
        return axios.post('work-approval/settings', payload);
    },

    put(payload) {
        return axios.put(`work-approval/settings/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`work-approval/settings/${payload.id}`, {
            data: payload
        });
    }
}

export {
    SettingService
}