/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react"

const BreadCrumbsContext = createContext({
    setMobile: _mobile => { },
    setDesktop: _desktop => { },
    setIcon: _icon => { }
})

const BreadCrumbsProvider = ({ children }) => {
    const [lists, setLists] = useState([])
    const [title, setTitle] = useState("")
    const [beside, setBeside] = useState(<></>)

    const value = {
        lists,
        setLists,
        title,
        setTitle,
        beside,
        setBeside
    }
    return (
        <BreadCrumbsContext.Provider value={value}>
            {children}
        </BreadCrumbsContext.Provider>
    )
}

function useBreadCrumbs() {
    return useContext(BreadCrumbsContext)
}

const BreadCrumbs = ({ children, lists, beside }) => {
    const { setLists, setTitle, setBeside } = useBreadCrumbs()

    useEffect(() => {
        if (lists) {
            setLists(lists)
        }
        return () => {
            setLists([])
        }
    }, [lists])

    useEffect(() => {
        if (children) {
            setTitle(children.toString())
        }
        return () => {
            setTitle("")
        }
    }, [children])

    useEffect(() => {
        if (beside) {
            setBeside(beside)
        }
        return () => {
            setBeside(<></>)
        }
    }, [beside])

    return <></>
}

export { BreadCrumbs, BreadCrumbsProvider, useBreadCrumbs }
