import AsideMenu from "./AsideMenu";
import Footer from "./Footer";

export default function Aside({ footer = [] }) {
	return (
		<div
			id="aside"
			className="aside bg-primary"
			data-kt-drawer="true"
			data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="250px"
			data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#aside_mobile_toggle"
			style={{ overflowY: "hidden" }}
		>
			<div className="aside-menu flex-column-fluid">
				<div
					id="aside_menu_wrapper"
					className="hover-scroll-overlay-y px-2 my-5 my-lg-5"
					data-kt-scroll="true"
					data-kt-scroll-height="auto"
					data-kt-scroll-dependencies="{default: '#aside_footer', lg: '#header, #aside_footer'}"
					data-kt-scroll-offset="0"
				>
					<div
						id="aside_body"
						className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
						data-kt-menu="true"
					>
						<AsideMenu />
					</div>
				</div>
				<div
					id="aside_footer"
					className="aside-footer flex-column-auto"
				>
					<Footer user={footer}></Footer>
				</div>
			</div>
		</div>
	);
}
