import { Rect, global, ResizeObserver, env, Plugin } from 'ckeditor5';

import {LinesRepository} from './ui/lines-repository.js';

const PLUGIN_ENABLED_CLASS = 'ck-pagination_enabled';

function findScrollableElement(documentRoot) {
    let castToNewRoot = documentRoot;
    do {
        castToNewRoot = castToNewRoot['parentElement'];
        const overflow = global['window']['getComputedStyle'](castToNewRoot)['overflowY'];
        if ('auto' === overflow || 'scroll' === overflow) break;
    } while ('BODY' !== castToNewRoot['tagName']);
    return castToNewRoot;
}

export class PaginationRenderer extends Plugin {
	constructor() {
		super(...arguments);
		this._scrollableEditingRootAncestor = null;
		this._resizeObserver = null;
	}

	init() {
		this.set({ pageNumber: 1 });

		this._linesRepository = new LinesRepository(this.editor);
		this._scrollableEditingRootAncestor = null;
		this._resizeObserver = null;
		this._paginationLookupPlugin = this.editor.plugins.get('PaginationLooker');

		this.bind('isEnabled').to(this.editor.plugins.get('PaginationEditing'));

		this.editor.ui.once('ready', () => this._onUiReady());

		this._toggleEditingRootsClass(PLUGIN_ENABLED_CLASS);

		this.on('change:isEnabled', (event, oldValue, newValue) => {
			if (!newValue) this._linesRepository.cleanLines();
			this._toggleEditingRootsClass(PLUGIN_ENABLED_CLASS, newValue);
		});
	}

	destroy() {
		this._toggleEditingRootsClass(PLUGIN_ENABLED_CLASS, false);
		this._linesRepository.destroy();
		this._scrollableEditingRootAncestor = null;

		if (this._resizeObserver) {
			this._resizeObserver.destroy();
		}
	}

	scrollToPage(pageNumber) {
		// if (!this.isEnabled) return;

		const offset = pageNumber > 1 ? this._getPageBreakLineOffset(pageNumber - 1) : 0;

		this._scrollableEditingRootAncestor.scrollTo({
			top: offset - 10,
			left: this._scrollableEditingRootAncestor.scrollLeft,
			behavior: env.isMotionReduced ? 'instant' : 'smooth'
		});
	}

	_onUiReady() {
		const editor = this.editor;
		const attachedDomRoot = this._getAttachedDomRoot();

		if (attachedDomRoot) {
			this._scrollableEditingRootAncestor = findScrollableElement(attachedDomRoot);

			editor.ui.view.listenTo(this._scrollableEditingRootAncestor, 'scroll', () => {
				this._updatePageBreakLines();
			}, { usePassive: true });

			editor.ui.view.listenTo(global.window, 'resize', () => this._updatePageBreakLines());

			this._resizeObserver = new ResizeObserver(this._scrollableEditingRootAncestor, () => {
				this._updatePageBreakLines();
			});

			this.listenTo(this._paginationLookupPlugin, 'change:pageBreaks', () => this._updatePageBreakLines());
		} else {
			editor.ui.once('update', () => this._onUiReady());
		}
	}

	_getAttachedDomRoot() {
		for (const domRoot of this.editor.editing.view.domRoots.values()) {
			if (domRoot.ownerDocument.body.contains(domRoot)) return domRoot;
		}
		return null;
	}

	_updatePageBreakLines() {
		// if (!this.isEnabled) return;

		const attachedDomRoot = this._getAttachedDomRoot();
		const pageBreaks = this._paginationLookupPlugin.pageBreaks;

		this._linesRepository.cleanLines();

		if (!attachedDomRoot || pageBreaks.length === 1) {
			this.pageNumber = 1;
			return;
		}

		const domRootRect = new Rect(attachedDomRoot).excludeScrollbarsAndBorders();
		const scrollableAncestorRect = new Rect(this._scrollableEditingRootAncestor).excludeScrollbarsAndBorders();
		const scrollThreshold = scrollableAncestorRect.top + scrollableAncestorRect.height / 3;

		let pageNumberUpdated = false;
		const pageBreakLines = [];

		for (let i = 1; i < pageBreaks.length; i++) {
			const offset = this._getPageBreakLineOffset(i, scrollableAncestorRect);
			if (!offset) continue;

			const pageOffsetTop = scrollableAncestorRect.top + offset - this._scrollableEditingRootAncestor.scrollTop;
			
			pageBreakLines.push({
				pageOffsetTop,
				domRootRect,
				domScrollableRect: scrollableAncestorRect,
				pageNumber: i
			});

			if (pageOffsetTop > scrollThreshold && !pageNumberUpdated) {
				this.pageNumber = i;
				pageNumberUpdated = true;
			}
		}

		this._renderPageBreakLines(pageBreakLines);

		if (!pageNumberUpdated) this.pageNumber = pageBreaks.length;
	}

	_renderPageBreakLines(pageBreakLines) {
		const { scrollX, scrollY } = global.window;

		if (pageBreakLines.length) {
			for (const { domScrollableRect, pageOffsetTop, domRootRect, pageNumber } of pageBreakLines) {
				if (domScrollableRect.top < pageOffsetTop && pageOffsetTop < domScrollableRect.bottom) {
					this._linesRepository.showLine(
						domRootRect.left + scrollX,
						pageOffsetTop + scrollY,
						domRootRect.width,
						pageNumber + 1
					);
				}
			}
		}
	}

	_getPageBreakLineOffset(pageIndex, scrollableRect = null) {
		const offset = this._paginationLookupPlugin.pageBreaks[pageIndex].offset;

		if (!offset) return 0;

		if (!scrollableRect) {
			scrollableRect = new Rect(this._scrollableEditingRootAncestor).excludeScrollbarsAndBorders();
		}

		return offset - scrollableRect.top + this._scrollableEditingRootAncestor.scrollTop;
	}

	_toggleEditingRootsClass(className, shouldAdd = true) {
		const view = this.editor.editing.view;
		view.change(writer => {
			for (const root of view.document.roots) {
				shouldAdd ? writer.addClass(className, root) : writer.removeClass(className, root);
			}
		});
	}
}
