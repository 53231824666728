import React from "react";
import SelectMultiple from "react-select";
import { Controller } from "react-hook-form";

const MultiSelect = ({  name, options = [], values = [], control, errors, onChanged = null}) => {

  return (
    <Controller
        control={control}
        defaultValue={values}
        rules={{required: true}}
        render={({ field: { value, onChange, name, onBlur, ref } }) => {
          return (
            <SelectMultiple
                name={name}
                ref={ref}
                placeholder={"Choose here . . . "}
                className="form-control bg-white border-0 p-0"
                options={options}
                isMulti={true}
                closeMenuOnSelect={false}
                ariaLiveMessages={errors[name]}
                onChange={(onChangeOptions) => (typeof onChanged == "function" ? onChanged(onChangeOptions, onChange) : onChange(onChangeOptions?.map((option) => option.value)))}
                onBlur={onBlur}
                value={options.filter((option) => values?.includes(option.value))}
                defaultValue={options.filter((option) => value?.includes(option.value))}
                required
            />
          );
        }}
        name={name}
      />
  );
};

export default MultiSelect;
