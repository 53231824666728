import { ButtonView, Plugin } from "ckeditor5";
import {PageNavigatorView} from "./ui/page-navigator-view";
import { getTranslation } from "./utils/common-translations";

var arrowDownIcon = '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 .75a9.25 9.25 0 110 18.5 9.25 9.25 0 010-18.5zm0 1.5a7.75 7.75 0 100 15.5 7.75 7.75 0 000-15.5zm0 2.022a.75.75 0 01.743.649l.007.101v8.165l2.714-2.705a.75.75 0 01.977-.07l.084.072a.75.75 0 01.07.976l-.072.084-3.994 3.981a.75.75 0 01-.975.073l-.084-.073-3.99-3.98a.75.75 0 01.975-1.135l.085.072 2.71 2.706V5.022a.75.75 0 01.75-.75z"/></svg>\n',
    arrowUpIcon = '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 19.25a9.25 9.25 0 100-18.5 9.25 9.25 0 000 18.5zm0-1.5a7.75 7.75 0 110-15.5 7.75 7.75 0 010 15.5zm0-2.022a.75.75 0 00.743-.649l.007-.101V6.813l2.714 2.705a.75.75 0 00.977.07l.084-.072a.75.75 0 00.07-.976l-.072-.084-3.994-3.981a.75.75 0 00-.975-.073l-.084.073-3.99 3.98a.75.75 0 00.975 1.135l.085-.072 2.71-2.706v8.166c0 .414.336.75.75.75z"/></svg>\n';

export class PaginationUI extends Plugin {
	static get pluginName() {
		return 'PaginationUI';
	}

	init() {
		const paginationEditing = this.editor.plugins.get('PaginationEditing');

		this.editor.ui.componentFactory.add('pageNavigation', locale => {
			const pageNavigator = new PageNavigatorView(locale);
			pageNavigator.bind('pageNumber').to(paginationEditing);
			pageNavigator.bind('totalPages').to(paginationEditing);
			pageNavigator.bind('isEnabled').to(paginationEditing);

			pageNavigator.on('navigation', (event, page) => {
				paginationEditing.moveSelectionToPage(page);
				paginationEditing.scrollToPage(page);
			});
			return pageNavigator;
		});

		this.editor.ui.componentFactory.add('nextPage', locale => {
			const nextPageButton = new ButtonView(locale);
			nextPageButton.isEnabled = true;
			nextPageButton.label = getTranslation(this.editor.locale, 'Go to next page');
			nextPageButton.icon = arrowDownIcon;
			nextPageButton.tooltip = true;
			nextPageButton.keystroke = 'Shift+PageDown';
			nextPageButton.bind('isEnabled').to(paginationEditing);

			this.listenTo(nextPageButton, 'execute', () => {
				paginationEditing.goToNextPage();
			});
			
			return nextPageButton;
		});

		this.editor.ui.componentFactory.add('previousPage', locale => {
			const previousPageButton = new ButtonView(locale);
			previousPageButton.isEnabled = true;
			previousPageButton.label = getTranslation(this.editor.locale, 'Go to previous page');
			previousPageButton.icon = arrowUpIcon;
			previousPageButton.tooltip = true;
			previousPageButton.keystroke = 'Shift+PageUp';
			previousPageButton.bind('isEnabled').to(paginationEditing);

			this.listenTo(previousPageButton, 'execute', () => {
				paginationEditing.goToPreviousPage();
			});
			return previousPageButton;
		});
	}
}
