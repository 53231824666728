import axios from "axios"
import { objectToUrl } from "../../../../Utility/Plugins";

const SystemAccessService = {
    get(payload = {}) {
        return axios.get(`admin/user/systems?${objectToUrl(payload)}`)
    },

    lists(payload = {}) {
        return axios.get(`admin/user/systems/access?${objectToUrl(payload)}`)
    },

    post(payload) {
        return axios.post('admin/user/systems', payload);
    },

    posts(payload) {
        return axios.post('admin/user/systems/updates', payload);
    },

    put(payload) {
        return axios.put(`admin/user/systems/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`admin/user/systems/${payload.id}`, {
            data: payload
        });
    }
}

export {
    SystemAccessService
}