import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Ajax from "../../../../Utility/Ajax";
import { LoadingSideList, NoPost } from "../utils/utils";

export default function TopChannels({ user, forum, onSelect }) {
	const navigate = useNavigate();
	const [topChannels, setTopChannels] = useState({
		processing: true,
		rows: [],
	});
	const onLoadTopChannel = (data) => {
		Ajax.post(
			"/forum-v2/channel/top-five/",
			{
				user_code: data.code,
			},
			() => {
				setTopChannels((old) => ({
					processing: true,
					rows: old.rows,
				}));
			},
			(result) => {
				switch (result.response) {
					case "resource-found": {
						setTopChannels({
							processing: false,
							rows: result.rows,
						});
						break;
					}
					default: {
						setTopChannels((old) => ({
							processing: false,
							rows: old.rows,
						}));
						break;
					}
				}
			},
			() => {
				setTopChannels((old) => ({
					processing: false,
					rows: old.rows,
				}));
			}
		);
	};

	useEffect(() => {
		if (user?.code) {
			onLoadTopChannel(user);
		}
	}, [user]);
	return (
		<div className="mb-5">
			<Card className="border border-1 border-gray-300 rounded-0">
				<Card.Header className="px-3 border-1 border-bottom border-gray-300">
					<Card.Title>
						<i className="fs-1 fas fa-newspaper me-2 text-info"></i> Top 5 News
						Channels
					</Card.Title>
				</Card.Header>
				<Card.Body className="p-5 py-3">
					<Row>
						{topChannels.processing
							? LoadingSideList()
							: topChannels.rows.length > 0
							? topChannels.rows.map((channel, i) => (
									<Col
										xs={12}
										className="d-flex justify-content-between py-3"
										key={channel.channel_code}
									>
										<div className="d-flex align-self-center">
											<div className="me-4 fs-5">{i + 1}</div>
											<div className="d-flex flex-column">
												<div className="ms-2 fs-5 mb-2">
													<div className="fw-bolder d-flex">
														<i
															className={`fw-bolder fs-2 ${channel.channel_icon}`}
														></i>
														<div className="ms-2 fs-5">
															{channel.channel_title}
														</div>
													</div>
												</div>
												<div className="ms-0">
													<div className="d-flex align-self-end">
														<div className="fs-8 p-0 px-3">
															{channel.total_comments > 1
																? `${channel.total_comments} Comments`
																: `${channel.total_comments} Comment`}
														</div>
														<div className="fs-8 p-0 px-3">
															{channel.total_posts > 1
																? `${channel.total_posts} Posts`
																: `${channel.total_posts} Post`}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<Button
												size="sm"
												variant="primary"
												className="px-2 py-1"
												onClick={() =>
													forum && typeof onSelect === "function"
														? onSelect(forum, () => {
																window.location.href = `/system/forum-v2/channel/${channel.channel_code}`;
														  })
														: navigate(
																`/system/forum-v2/channel/${channel.channel_code}`
														  )
												}
											>
												View
											</Button>
										</div>
									</Col>
							  ))
							: NoPost("No channels yet")}
					</Row>
				</Card.Body>
				<Card.Footer className="border-1 border-top border-gray-300 py-0 d-flex justify-content-center">
					<Button
						size="sm"
						variant="link"
						onClick={() =>
							forum && typeof onSelect === "function"
								? onSelect(forum, () => {
										window.location.href = "/system/forum-v2/channels/";
								  })
								: navigate("/system/forum-v2/channels/")
						}
					>
						View All Channels
					</Button>
				</Card.Footer>
			</Card>
		</div>
	);
}
