import { View, InputNumberView, keyCodes } from 'ckeditor5';
import { getTranslation } from '../utils/common-translations';

export class PageNavigatorView extends View {
	constructor(locale) {
        super(locale);
        
		const bindTemplate = this.bindTemplate;

		this.set({
			pageNumber: 1,
			totalPages: 1,
			isEnabled: true
		});

		this.pageNumberView = new InputNumberView(locale, {
			min: 1,
			step: 1
		});

		this.pageNumberView.set({
			inputMode: 'numeric',
			tabIndex: -1
		});

		this.pageNumberView.bind('ariaLabel').to(this, 'totalPages', totalPages => 
			getTranslation(locale, 'Current page number (from 1 to %0)', totalPages)
		);

		this.pageNumberView.bind('max').to(this, 'totalPages');
		this.pageNumberView.bind('isReadOnly').to(this, 'isEnabled', isEnabled => !isEnabled);

		this.setTemplate({
			tag: 'div',
			attributes: {
				class: ['ck', 'ck-page-navigator', bindTemplate.if('isEnabled', 'ck-disabled', isEnabled => !isEnabled)]
			},
			children: [
				this.pageNumberView,
				{
					tag: 'span',
					children: [
						{ text: bindTemplate.to('totalPages') }
					]
				}
			]
		});
	}

	render() {
		super.render();
		const pageNumberInput = this.pageNumberView.element;

		const updatePageNumberInput = newPageNumber => {
			this.pageNumberView.element.value = String(newPageNumber);
		};

		updatePageNumberInput(this.pageNumber);

		this.on('change:pageNumber', (event, oldPageNumber, newPageNumber) => {
			updatePageNumberInput(newPageNumber);
		});

		this.listenTo(pageNumberInput, 'keydown', (event, domEvent) => {
			if (domEvent.key === 'ArrowUp' || domEvent.key === 'ArrowDown') {
				domEvent.stopPropagation();
				return;
			}
			
			if (domEvent.keyCode !== keyCodes.enter) return;

			domEvent.preventDefault();
			const enteredPageNumber = pageNumberInput.valueAsNumber;

			if (Number.isNaN(enteredPageNumber)) {
				pageNumberInput.value = String(this.pageNumber);
			} else {
				if (pageNumberInput.checkValidity()) {
					this.pageNumber = enteredPageNumber;
				} else if (enteredPageNumber > this.totalPages) {
					this.pageNumber = this.totalPages;
					pageNumberInput.value = String(this.totalPages);
				} else {
					this.pageNumber = 1;
					pageNumberInput.value = '1';
				}
				this.fire('navigation', this.pageNumber);
			}
		});

		this.listenTo(pageNumberInput, 'blur', () => {
			pageNumberInput.value = String(this.pageNumber);
		});

		this.listenTo(pageNumberInput, 'focus', () => {
			this.pageNumberView.select();
		});
	}

	focus() {
		this.element.firstChild.focus();
	}
}

