import { Rect, isText } from "ckeditor5";

const findImages = (element, images = []) => {
    const imageTypes = ["imageInline", "imageBlock", "image"];
    
    if (element.childCount >= 1) {
        Array.from(element.getChildren()).forEach(child => findImages(child, images));
    } else {
        if (imageTypes.includes(element.name)) {
            images.push(element);
        }
    }

    return images;
};

const findBase64EncodedImages = (images) => {
    return Array.from(images).filter(
        image => image.hasAttribute('src') && String(image.getAttribute('src')).substring(0, 10).indexOf('data:') >= 0
    );
};
        
function getTranslation(context, key, ...params) {
    const t = context['t'];

    switch (key) {
        case 'Go to next page':
            return t('Go to next page');
        case 'Go to previous page':
            return t('Go to previous page');
        case 'Current page number (from 1 to %0)':
            return t('Current page number (from 1 to %0)', ...params);
        case 'Keystrokes for navigating through documents':
            return t('Keystrokes for navigating through documents');
        case 'Go to the previous page (also move selection)':
            return t('Go to the previous page (also move selection)');
        case 'Go to the next page (also move selection)':
            return t('Go to the next page (also move selection)');
        default:
            return key;
    }
}

// Creates a DOM Range that selects the entire node.
function createDomRangeOn(node) {
    const range = global.document.createRange();
    range.selectNode(node);
    return range;
}

// Creates a DOM Range that selects only the contents of the given node.
function createDomRangeIn(node) {
    const range = global.document.createRange();
    range.selectNodeContents(node);
    return range;
}

// Creates a DOM Range within a single node, specifying start and end offsets.
function createDomRange(node, startOffset, endOffset) {
    const range = global.document.createRange();
    range.setStart(node, startOffset);
    range.setEnd(node, endOffset);
    return range;
}

// Creates a DOM Range spanning across two nodes, specifying start and end nodes with their respective offsets.
function createDomRangeMultiNode(startNode, startOffset, endNode, endOffset) {
    const range = global.document.createRange();
    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    return range;
}


function shouldIgnoreFigure(figureElement) {
    // Assume we should not ignore the figure initially
    let ignoreFigure = false;
    
    // Retrieve necessary properties
    const rootName = figureElement.parentElement.dataset.rootName;
    const nextSibling = figureElement.nextElementSibling;
    const previousSibling = figureElement.previousElementSibling;
    const nextSiblingFloat = nextSibling ? global.window.getComputedStyle(nextSibling).float : null;
    const isFakeSelection = nextSibling ? nextSibling.className === 'ck-fake-selection-container' : false;

    // Determine if the figure should be ignored based on various conditions
    if (!rootName || nextSibling || previousSibling) {
        ignoreFigure = true;
    }
    
    if (rootName && !previousSibling && isFakeSelection) {
        ignoreFigure = true;
    }

    if (rootName && !previousSibling && nextSiblingFloat !== 'none') {
        ignoreFigure = true;
    }

    return !ignoreFigure; // Return true if we should not ignore the figure
}


function isHTMLElement(node) {
    return node?.nodeType === Node.ELEMENT_NODE;
}

// function isPageBreak(element) {
//     // Check if the element is a valid HTML element and has the 'page-break' class
//     return (
//         isHTMLElement(element) &&
//         (element.classList.contains('page-break') || element.closest('.page-break'))
//     );
// }


function isPageBreak(element) {
    // Check if the element is a valid HTML element and has the 'page-break' class
    return (
        isHTMLElement(element) && (element.classList.contains('page-break'))
    );
}

function findOffsetTopForTextOffset(targetNode, textOffset, rangeNode) {
    const rangeOnTarget = createDomRangeOn(targetNode);
    const rangeInNode = createDomRangeIn(rangeNode);
    
    let initialRange;

    // Determine the initial range based on the text offset
    if (textOffset === 0) {
        initialRange = createDomRangeMultiNode(
            rangeInNode.startContainer,
            rangeInNode.startOffset,
            targetNode.parentNode,
            Array.from(targetNode.parentNode.childNodes).indexOf(targetNode)
        );
    } else {
        initialRange = createDomRangeMultiNode(
            rangeInNode.startContainer,
            rangeInNode.startOffset,
            targetNode,
            textOffset
        );
    }

    const endRange = createDomRangeMultiNode(
        targetNode,
        textOffset,
        rangeInNode.endContainer,
        rangeInNode.endOffset
    );

    // Calculate the vertical position
    const initialBottom = new Rect(initialRange).bottom;
    const endTop = new Rect(endRange).top;
    const offsetTop = (endTop + initialBottom) / 2 - new Rect(rangeOnTarget).top;

    return offsetTop;
}


function findPageStartersInLeafElementNode(leafNode, index, offsetIndex, pageHeight, isFirstPage) {
    const starters = [];
    if (global.window.getComputedStyle(leafNode).display === 'table-cell') return [];

    const clientRects = [];
    for (const rect of leafNode.getClientRects()) {
        if (rect.left >= offsetIndex) {
            for (const existingRect of clientRects) {
                existingRect.height += rect.height;
            }
            clientRects.push({ height: rect.height });
            offsetIndex += pageHeight;
        }
    }
    
    for (const rect of clientRects) {
        if (!isFirstPage) {
            starters.push({
                type: 'element',
                offset: rect.height,
                textOffset: 0,
                path: [index]
            });
        }
        isFirstPage = true;
    }
    return starters;
}

function findPageStartersInElementNode(elementNode, index, offsetIndex, pageHeight, isFirstPage) {
    const starters = [];
    if (isPageBreak(elementNode.previousSibling)) {
        starters.push({
            type: 'manual',
            offset: 0,
            path: [index - 1]
        });
        isFirstPage = true;
    }
    const closestFigure = elementNode.closest('figure.image');
    if (closestFigure && shouldIgnoreFigure(closestFigure)) {
        const floatStyle = global.window.getComputedStyle(closestFigure).float;
        if (floatStyle === 'left' || floatStyle === 'right') {
            return starters;
        }
    }
    const foundInfos = findPageStarterInfos(elementNode, offsetIndex, pageHeight, isFirstPage);
    if (foundInfos.length) {
        for (const info of foundInfos) {
            starters.push({
                type: info.type,
                offset: info.offset,
                textOffset: info.textOffset,
                path: [index, ...info.path]
            });
            offsetIndex += pageHeight;
            isFirstPage = true;
        }
        return starters;
    }
    starters.push(...findPageStartersInLeafElementNode(elementNode, index, offsetIndex, pageHeight, isFirstPage));
    return starters;
}



function domRangeContainsPageBreak(range, pageLeft, pageHeight) {
    for (const rect of range.getClientRects()) {
        if (rect.left >= pageLeft && rect.left < pageLeft + pageHeight) return true;
    }
    return false;
}

function findLastTextOffsetOnPage(textNode, start, pageLeft, pageHeight) {
    let low = start;
    let high = textNode.length;
    while (high - low > 1) {
        const mid = Math.ceil((low + high) / 2);
        const range = createDomRange(textNode, low, mid);
        if (domRangeContainsPageBreak(range, pageLeft, pageHeight)) {
            high = mid;
        } else {
            low = mid;
        }
    }
    return low;
}

function findPageStartersInTextNode(textNode, index, offsetIndex, pageHeight, isFirstPage) {
    const range = createDomRangeOn(textNode);
    const foundStarters = [];
    let currentOffset = 0;
    for (const rect of range.getClientRects()) {
        if (rect.left >= offsetIndex) {
            const textOffset = findLastTextOffsetOnPage(textNode, currentOffset, offsetIndex, pageHeight);
            if (!isFirstPage) {
                foundStarters.push({
                    type: 'text',
                    path: [index],
                    textOffset: textOffset
                });
            }
            currentOffset = textOffset;
            offsetIndex += pageHeight;
            isFirstPage = true;
        }
    }
    return foundStarters;
}

function findPageStarterInfos(container, offsetIndex, pageHeight, isFirstPage) {
    const pageStarters = [];
    for (const [index, child] of container.childNodes.entries()) {
        let starters = [];
        if (isHTMLElement(child) && child.offsetParent && !child.style.position) {
            starters = findPageStartersInElementNode(child, index, offsetIndex, pageHeight, isFirstPage);
        } else if (isText(child)) {
            starters = findPageStartersInTextNode(child, index, offsetIndex, pageHeight, isFirstPage);
        }

        if (starters.length) {
            pageStarters.push(...starters);
            offsetIndex += starters.length * pageHeight;
            isFirstPage = true;
        }
    }
    return pageStarters;
}

function pageElementToViewElement(pageElement, viewPath) {
    let currentElement = pageElement;

    // Traverse through the view path to find the corresponding child node
    for (const index of viewPath.path) {
        if (!currentElement) {
            return null; // Return null if the current element is not found
        }
        currentElement = currentElement.childNodes[index]; // Update to the next child node
    }

    return currentElement; // Return the final found element
}

function getTopMostParentBlockTouchingPosition(childElement) {
    const parentElement = childElement.parent;
    const modelDocument = parentElement.root.document.model;
    const ancestors = parentElement.getAncestors({
        parentFirst: true,
        includeSelf: true
    });

    let topMostBlock = null;

    for (const ancestor of ancestors) {
        if (ancestor.is('rootElement')) {
            return topMostBlock; // Return null if we reach the root element
        }
        if (!modelDocument.createPositionBefore(ancestor).isTouching(childElement)) {
            return topMostBlock; // Return the last touching block if no touching found
        }
        topMostBlock = ancestor; // Update topMostBlock to the current ancestor
    }

    return topMostBlock; // Return the top most block that touches the child element
}


function imageReady(imageElement) {
    return new Promise(resolve => {
        function onImageLoadOrError() {
            imageElement.removeEventListener('load', onImageLoadOrError);
            imageElement.removeEventListener('error', onImageLoadOrError);
            resolve(); // Resolve the promise when the image is ready
        }

        // Check if the image is already complete
        if (imageElement.complete) {
            resolve();
        } else {
            imageElement.addEventListener('load', onImageLoadOrError);
            imageElement.addEventListener('error', onImageLoadOrError);
        }
    });
}

function timeout(delay) {
    return new Promise(resolve => setTimeout(resolve, delay));
}


function allImagesAreReady(images) {
    return Promise.all(
        images.map(image => 
            Promise.race([
                imageReady(image),
                timeout(1000) // Timeout of 1000ms
            ])
        )
    );
}



export {
    getTranslation,
    allImagesAreReady,
    getTopMostParentBlockTouchingPosition,
    pageElementToViewElement,
    findOffsetTopForTextOffset,
    findPageStarterInfos,
    findImages,
    findBase64EncodedImages,
    isHTMLElement,
    createDomRange,
    createDomRangeIn,
    createDomRangeMultiNode,
    createDomRangeOn
}