import axios from "axios"

const TimelineService = {
    get(payload) {
        return axios.get(`work-approval/timelines?${payload}`);
    },

    post(payload) {
        return axios.post('work-approval/timelines', payload);
    },

    put(payload) {
        return axios.put(`work-approval/timelines/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`work-approval/timelines/${payload.id}`, {
            data: payload
        });
    }
}

export {
    TimelineService
}