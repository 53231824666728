import Swal from "sweetalert2";
import Ajax from "./Ajax";

export const objectToUrl = (objects) => {
    return Object.keys(objects).map(
        key => {
            if (typeof objects[key] === 'undefined' || objects[key] === 'null' || objects[key] === null) {
                return `${key}=`
            }

            return `${key}=${objects[key]}`
        }
    ).join('&').toString()
}

export const Alert = (title, html, icon = false, confirmButton = false, cancelButton = false, closeButton = false) => {
    if (confirmButton && typeof (confirmButton) === "boolean") confirmButton = () => { };
    if (cancelButton && typeof (cancelButton) === "boolean") cancelButton = () => { };
    if (closeButton && typeof (closeButton) === "boolean") closeButton = () => { };

    const confirm = typeof (confirmButton) === "function",
        cancel = typeof (cancelButton) === "function",
        close = typeof (closeButton) === "function",
        options = {
            title: `<b>${title}</b>`,
            html: html,
            showConfirmButton: confirm,
            confirmButtonText: "OK",
            focusConfirm: false,
            showLoaderOnConfirm: confirm,
            preConfirm: confirm ? t => t : null,
            showCancelButton: cancel,
            cancelButtonText: "CANCEL",
            showCloseButton: close,
            customClass: {
                confirmButton: "bg-primary",
                cancelButton: "bg-danger"
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        };

    if (typeof (icon) === "object") {
        options.imageUrl = icon.src;
        options.imageHeight = icon.height;
        options.imageAlt = icon.alt;
    } else if (typeof (icon) === "string") {
        options.icon = icon;
    }

    Swal.fire(options).then((btn => {
        btn.isConfirmed && (confirm ? confirmButton(btn) : btn.isDismissed && (closeButton ? closeButton(btn) : btn.isDenied && cancel && cancelButton(btn)))
    }));

    return Swal;
}

export const DivState = (isLoading = false, label = "", color = "") => {
    return (
        isLoading ?
            <div className="fs-1 d-flex justify-content-center align-items-center">
                <b className={`mt-auto text-center py-5 text-${color || "warning"}`}>
                    <b className="fs-1 fas fa-spinner fa-spin col-12"></b>
                    {label || " - - GATHERING DATA - - "}
                </b>
            </div>
            :
            <div className="fs-1 d-flex justify-content-center align-items-center">
                <b className={`mt-auto text-center text-${color || "danger"}`}>
                    {label || " - - NO DATA - - "}
                </b>
            </div>
    );
}

export const TableState = (colSpan = 0, isLoading = false, label = "", color = "") => {
    return (
        isLoading ? (
            <tr>
                <td colSpan={colSpan} className={`fs-1 text-center py-20 text-${color || "warning"}`}>
                    <p><i className="fs-1 text-warning fas fa-spinner fa-spin"></i></p>
                    <p>{label || " - - GATHERING TABLE DATA - - "}</p>
                </td>
            </tr>
        ) : (
            <tr>
                <td colSpan={colSpan} className={`fs-1 text-center py-20 text-${color || "danger"}`}>
                    <p>{label || " - - NO TABLE DATA - - "}</p>
                </td>
            </tr>
        )
    )
}

export const ReactQuillToolbars = [
    // [{ 'font': [] }],
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],  //, 'strike'      // toggled buttons
    //['blockquote', 'code-block'],
    //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'align': [] }, { 'indent': '+1' }],          // outdent/indent
    //[{ 'direction': 'rtl' }],                         // text direction
    ['link', 'image'], // 'image'
    ['clean']
];

export const TableSearch = (data, input, filters) => {
    const searchFor = input.target.value.toLowerCase();

    if (data && searchFor.length > 0) {
        const filteredData = data.filter((row, i) => {
            let checker = "";
            Object.keys(row).filter(key => filters.includes(key))
                .forEach(val => {
                    checker += row[val]?.toLowerCase() || ""
                });
            return checker.indexOf(searchFor) >= 0;
        });

        return {
            status: filteredData.length > 0 ? "exist" : "not-exist",
            data: filteredData
        }
    }
    else {
        return {
            status: data.length > 0 ? "exist" : "no-data",
            data: data
        }
    }

}

export const CreatePage = (pagination, onClick, format = []) => {
    if (pagination) {
        format.push(
            <li key={0} className={`page-item ${pagination.previous ? "" : "disabled"}`}>
                <button className="page-link" onClick={() => onClick(pagination, pagination.currentPage - 1)} tabIndex="-1" aria-disabled="true">Prev</button>
            </li>
        );

        for (let page = 1; page <= pagination.pageCount; page++) {
            format.push(
                <li key={page} className={`page-item ${pagination.currentPage === page ? "active" : ""}`}>
                    <button className="page-link" onClick={() => onClick(pagination, page)} tabIndex="-1" aria-disabled="true">{page}</button>
                </li>
            );
        }

        format.push(
            <li key={pagination.pageCount + 1} className={`page-item ${pagination.next ? "" : "disabled"}`}>
                <button className="page-link" onClick={() => onClick(pagination, pagination.currentPage + 1)} tabIndex="-1" aria-disabled="true">Next</button>
            </li>
        );
    }

    return {
        ...pagination,
        format: format,
        from: ((pagination.currentPage * pagination.perPage) - (pagination.perPage - 1)) || 0,
        to: (((pagination.currentPage * pagination.perPage) > pagination.total) ? pagination.total : (pagination.currentPage * pagination.perPage)) || 0,
        total: pagination.total || 0
    };
}


export const isValid = (error, key) => {

    if (error[key]?.message) {
        return false;
    }

    return true
}

export const isInvalid = (error, key) => {
    if (error[key]?.message) {
        return true;
    }

    return false
}


export const userProfilePath = (user_code) => `${Ajax.env.API_URI}/profile/${user_code}.png?v=${Math.floor(Math.random() * 100)}`;