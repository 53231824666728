import axios from "axios"
import { objectToUrl } from "../../../Utility/Plugins";

const AttachmentService = {
    get(payload = {}) {
        return axios.get(`work-approval/attachments?${objectToUrl(payload)}`)
    },

    post(payload, progressCallback = null) {
        return axios.post('work-approval/attachments', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent
                const progress = Math.round((loaded * 100) / total).toFixed(2)
                if (typeof progressCallback === 'function') {
                    progressCallback(progress, loaded, total)
                }
            }
        });
    },

    upload(payload, progressCallback = null) {
        return axios.post('work-approval/attachments/images', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent
                const progress = Math.round((loaded * 100) / total).toFixed(2)
                if (typeof progressCallback === 'function') {
                    progressCallback(progress, loaded, total)
                }
            }
        });
    },

    put(payload) {
        return axios.put(`work-approval/attachments/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`work-approval/attachments/${payload.id}`, {
            data: payload
        });
    }
}

export {
    AttachmentService
}