import { Button, ButtonGroup, Card, Col, Image } from "react-bootstrap";

export const ReactQuillToolbarsForum = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],  //, ''      // toggled buttons
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'align': [] }, { 'indent': '+1' }],          // outdent/indent
    ['link', 'image', 'video'], // ,
    //['clean']                                         // remove formatting button
];

export const LoadingPost = (title = "Loading posts") => (
    <Col xs={12} className="px-0">
        <Card className="border border-2 rounded-0 shadow-sm">
            <Card.Header className="p-4 border-0">
                <Card.Title className="col-10 p-0 m-0">
                    <div className="col-12 d-flex">
                        <div className="placeholder-glow me-2">
                            <div className="min-w-50px rounded-circle h-50px placeholder"></div>
                        </div>
                        <div className="col-6 row px-3 py-3 placeholder-glow">
                            <span className="placeholder col-12 mb-2"></span>
                            <span className="placeholder col-4"></span>
                        </div>
                    </div>
                </Card.Title>
                <div className="card-toolbar col-2 py-0 placeholder-glow">
                    <span className="placeholder col-12 align-self-start mt-2"></span>
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <h5 className="px-3 py-3 d-flex flex-column placeholder-glow border-bottom border-2">
                    <span className="placeholder col-3 mb-2"></span>
                    <span className="placeholder col-7"></span>
                </h5>
                <div className="px-3 py-3 placeholder-glow">
                    <span className="align-self-end placeholder col-10"></span>
                </div>
                <div className="d-flex flex-md-row flex-column justify-content-between p-3">
                    <div className="placeholder-glow col-3">
                        <span className="placeholder col-12"></span>
                    </div>
                    <div className="d-flex align-self-end col-4">
                        <div className="p-0 px-3 placeholder-glow col-6">
                            <span className="placeholder col-12"></span>
                        </div>
                        <div className="p-0 px-3 placeholder-glow col-6">
                            <span className="placeholder col-12"></span>
                        </div>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer className="px-6 py-2 border-0 d-flex justify-content-end">
                <ButtonGroup className="col-8">
                    <div className="placeholder-glow col-4 px-2">
                        <span className="placeholder col-12"></span>
                    </div>
                    <div className="placeholder-glow col-4 px-2">
                        <span className="placeholder col-12"></span>
                    </div>
                    <div className="placeholder-glow col-4 px-2">
                        <span className="placeholder col-12"></span>
                    </div>
                </ButtonGroup>
            </Card.Footer>
        </Card>
    </Col>
);

export const LoadingRipple = () => (
    <Col xs={12} className="d-flex flex-column align-items-center justify-content-center py-5 my-5">
        <div>
            <img className="min-w-250px" src="/assets/media/svg/loader.svg" alt="loader.svg" />
        </div>
    </Col>
);

export const LoadingChannel = () => (
    <Col xs={12} className="mb-5 px-0" >
        <Card className="border border-2">
            <Card.Header className="px-5 border-0">
                <div className="d-flex justify-content-start col-7 fs-3">
                    <div className="align-self-center py-3 me-3 placeholder-glow">
                        <div className="min-w-35px rounded-circle h-35px placeholder"></div>
                    </div>
                    <div className="col-12 align-self-center placeholder-glow">
                        <span className="placeholder col-12 mb-2"></span>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-between  py-0 px-0">
                <div className="col-5 d-flex pb-2">
                    <div className="col-5 px-3 placeholder-glow">
                        <span className="col-12 placeholder"></span>
                    </div>
                    <div className="col-5 px-3 placeholder-glow">
                        <span className="col-12 placeholder"></span>
                    </div>
                </div>
                <div className="col-5 d-flex justify-content-end pb-2">
                    <div className="col-5 px-3 placeholder-glow">
                        <span className="col-12 placeholder"></span>
                    </div>
                    <div className="col-5 px-3 placeholder-glow">
                        <span className="col-12 placeholder"></span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </Col>
);

export const LoadingUser = () => (
    <Col xs={12} className="mb-5 px-0" >
        <Card className="border border-2">
            <Card.Header className="px-5 border-0">
                <div className="d-flex justify-content-start col-9 fs-3">
                    <div className="align-self-center py-3 me-3 placeholder-glow">
                        <div className="min-w-35px rounded-circle h-35px placeholder"></div>
                    </div>
                    <div className="col-12 align-self-center placeholder-glow">
                        <span className="placeholder col-12 mb-2"></span>
                    </div>
                </div>
            </Card.Header>
        </Card>
    </Col>
);

export const LoadingSideList = () => (
    <Col xs={12} className="px-0" >
        <div className="row ps-3">
            <div className="d-flex justify-content-start fs-3">
                <div className="align-self-center py-3 me-3 placeholder-glow">
                    <div className="min-w-35px rounded-circle h-35px placeholder"></div>
                </div>
                <div className="col-12 align-self-center placeholder-glow">
                    <span className="placeholder col-9 mb-2"></span>
                </div>
            </div>
        </div>
    </Col>
);

export const LoadingComment = () => (
    <Col xs={12} className="px-0">
        <Card className="border border-2">
            <Card.Header className="p-4">
                <div className="col-12 d-flex">
                    <div className="placeholder-glow me-2">
                        <div className="min-w-35px rounded-circle h-35px placeholder"></div>
                    </div>
                    <div className="col-6 row px-3 py-3 placeholder-glow">
                        <span className="placeholder col-12 mb-2"></span>
                        <span className="placeholder col-4"></span>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <div className="px-3 py-3 placeholder-glow">
                    <span className="align-self-end placeholder col-10"></span>
                </div>
            </Card.Body>
            <Card.Footer className="px-3 py-2 border-0 bg-gray-100">
                <div className="d-flex justify-content-end ">
                    <div className="placeholder-glow col-2">
                        <span className="placeholder col-12"></span>
                    </div>
                </div>
            </Card.Footer>
        </Card>
    </Col>
);


export const NoPost = (title = "No post yet") => (
    <Col xs={12} className="d-flex flex-column align-items-center justify-content-center py-5 my-5">
        <div>
            <Image src="/assets/media/no-post.png" />
        </div>
        <div className="fs-2 text-primary">
            - - - {title} - - -
        </div>
    </Col>
);

export const LoadMore = () => (
    <Col xs={12} className="d-flex justify-content-center">
        <div className="col-12">
            <Button variant="outline-primary" className="col-12 text-hover-white">
                Load More
            </Button>
        </div>
    </Col>
);

export const check = (priority) => {
    switch (priority) {
        case "MEDIUM": {
            return "info";
        }
        case "HIGH": {
            return "warning";
        }
        case "CRITICAL": {
            return "danger";
        }
        default: {
            return "secondary";
        }
    }
}

export const checkStatus = (status) => {
    switch (status) {
        case "CLOSED": {
            return "danger";
        }
        default: {
            return "primary";
        }
    }
}

export const checkRequired = (usr, prm, ch) => usr && prm && ch && prm?.channel_code && prm?.post_code;

export const enhanceUrlToObject = (url) => {
    if (null === url) return null;
    const est = new URL(url);
    return est.searchParams.get("page")
}

export const validateReceivedData = (post, received) => (received && post) && (post.channel_code === received.channel_code) && (post.post_code === received.post_code);