
import Header from "./Header/Header";

import Brand from "./Header/Brand";
import Toolbar from "./Header/Toolbar";

import ContentContainer from "./ContentContainer";

import Footer from "./Footer";
import { Outlet } from "react-router-dom";

export default function Wrapper({ children, className }) {
    return (
		<div
			id="wrapper"
			className={`wrapper d-flex flex-column flex-row-fluid ${className}`}
		>
			<Header className={className}>
				<Brand />
				<Toolbar />
			</Header>
			<ContentContainer>
				<Outlet />
			</ContentContainer>
			<Footer href="#" title={process.env.REACT_APP_COMPANY_TITLE} />
		</div>
	);
}