import { ButtonView, Plugin } from "ckeditor5";

class Print extends Plugin {

    init() {
        // Add a button to the component factory so it is available for the editor.
        this.editor.ui.componentFactory.add('print', locale => {
            
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Print Preview',
                withText: true,
                class: 'fw-bolder text-dark',
                tooltip: 'Preview Letter',
                keystroke: 'Alt+P'
            });

            // Define the button behavior on press.
            buttonView.on('execute', () => {
                this.editor.ui.view.fire('printWorkApprovalDocument');
            });

            return buttonView;
        });

    }
}

export {
    Print
}