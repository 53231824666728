import axios from "axios"
import { objectToUrl } from "../../../../Utility/Plugins";

const UserAccountService = {
    get(payload = {}) {
        return axios.get(`admin/user/accounts?${objectToUrl(payload)}`)
    },

    lists(payload = {}) {
        return axios.get(`admin/user/accounts/lists?${objectToUrl(payload)}`)
    },

    post(payload) {
        return axios.post('admin/user/accounts', payload);
    },

    put(payload) {
        return axios.put(`admin/user/accounts/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`admin/user/accounts/${payload.id}`, {
            data: payload
        });
    }
}

export {
    UserAccountService
}