import React from "react";
import ReactDOM from "react-dom/client";
import Authenticate from "./Authentication";
import Success from "./Signin/Success";

import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { getNotificationPermission } from "./Utility/NotificationHelper";

import SelectSystemWrappper from "./SelectSystemWrappper";
import PrintPreview from "./System/WorkApproval/pages/PrintPreview";

import AdminWrapper from "./System/Admin/AdminWrapper";
import { WorkApprovalWrapper } from "./System/WorkApproval/WorkApprovalWrapper";
import { ForumV2Wrapper } from "./System/ForumV2/ForumV2Wrapper";
import { UserWrapper } from "./System/User/UserWrapper";
import { GoogleFormWrapper } from "./System/GoogleForm/GoogleFormWrapper";

import './Utility/axios';
import './Utility/registries';

import { PageAccessService } from "./System/Admin/services/users/page-access.service";

// require("dotenv").config();

function RootWrapper() {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<RootWrapperClass
			navigate={navigate}
			location={location}
		/>
	)
}

class RootWrapperClass extends React.Component {

	constructor() {
		super();

		this.state = {
			fetchingUser: true,
			isAccessing: true,
			user: null,
			system: null,
			path: null
		}
	}

	componentDidMount() {
		this.setState(() => {
			const hasUserStorage = localStorage.getItem("user");
			if (hasUserStorage) {

				const hasSystemStorage = localStorage.getItem("system");
				if (hasSystemStorage) {
					return {
						fetchingUser: false,
						isAccessing: false,
						user: JSON.parse(hasUserStorage),
						system: JSON.parse(hasSystemStorage),
						path: this.props.location.pathname.split('/')
					};
				} else {
					return {
						fetchingUser: false,
						isAccessing: false,
						user: JSON.parse(hasUserStorage),
						system: null,
						path: this.props.location.pathname.split('/')
					};
				}
			}

			return {
				fetchingUser: false,
				isAccessing: false,
				user: null,
				system: null,
				path: this.props.location.pathname.split('/')
			};
		}, () => {
			if (this.state.user) {
				getNotificationPermission();

				if (this.state.system && !this.state.path.includes('print-preview') && !this.state.path.includes('user')) {
					this.onAccessSytem(this.state.system);
				}
			}
		});
	}

	render() {

		if (this.state.fetchingUser || this.state.isAccessing) {
			return <></>
		}

		if (this.state.user) {
			return (
				<Routes>
					<Route exact path="system/admin/*" element={<AdminWrapper />} />
					<Route exact path="system/work-approval/*" element={<WorkApprovalWrapper />} />
					<Route exact path="system/work-approval/print-preview/:token" element={<PrintPreview />} />
					<Route exact path="system/google-form/*" element={<GoogleFormWrapper />} />
					<Route exact path="system/forum-v2/*" element={<ForumV2Wrapper />} />
					<Route exact path="system/user/*" element={<UserWrapper />} />
					<Route exact path="*" element={<SelectSystemWrappper />} index />
				</Routes>
			);
		}

		return (
			<Routes>
				{
					/* {Boolean(process.env.REACT_APP_SWAP_SEARCH_USER_TO_REGISTRATION) ? (
						<Route exact path="/search-user" index element={<SearchUser />} />
					) : (
						<Route exact path="/register" index element={<Registration />} />
					)} */
				}
				<Route exact path="system/oauth/success" element={<Success />} />
				<Route
					exact
					path="*"
					element={
						<Authenticate
							setUser={
								user => {
									this.setState({
										user: user
									});
								}
							}
						/>
					}
				/>
			</Routes>
		);
	}

	onAccessSytem(system) {
		this.setState({
			selectedSystem: system.code,
			isAccessing: true
		}, () => {
			const path = system.href.split('/');
			if (path.length >= 2 && !this.state.path.includes(path[1])) {
				PageAccessService.lists({
					user_code: this.state.user.code,
					system_code: system.code,
				}).then(pages => {
					this.setState({
						isAccessing: false
					}, () => {
						localStorage.setItem("system", JSON.stringify(system));
						localStorage.setItem("pages", JSON.stringify(pages));
						this.props.navigate(`system${system.href}`);
					});
				}).catch(() => {
					this.setState({
						isAccessing: false
					});
				});
			} else {
				this.setState({
					isAccessing: false
				});
			}
		});
	}
}

const appRoot = ReactDOM.createRoot(document.getElementById('root'))

appRoot.render(
	<React.StrictMode>
		<BrowserRouter>
			<RootWrapper />
		</BrowserRouter>
	</React.StrictMode>,
);
