/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react"

const AsideMenuContext = createContext({
    setLists: _lists => { }
})

const AsideMenuProvider = ({ children }) => {
    const [lists, setLists] = useState([])

    const value = {
        lists,
        setLists
    }
    return (
        <AsideMenuContext.Provider value={value}>
            {children}
        </AsideMenuContext.Provider>
    )
}

function useAsideMenu() {
    return useContext(AsideMenuContext)
}

const AsideMenu = ({ lists }) => {
    const { setLists } = useAsideMenu()

    useEffect(() => {
        if (lists) {
            setLists(lists)
        }
        return () => {
            setLists([])
        }
    }, [lists])

    return <></>
}

export { AsideMenu, AsideMenuProvider, useAsideMenu }
