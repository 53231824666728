import { View, toUnit } from 'ckeditor5';

const toPx = toUnit('px');

export class LineView extends View {
	constructor() {
		super();

		const bindTemplate = this.bindTemplate;

		this.set({
			isVisible: false,
			left: null,
			top: null,
			width: null,
			pageNumber: null
		});

		this.setTemplate({
			tag: 'div',
			attributes: {
				'data-page-number': bindTemplate.to('pageNumber'),
				class: [
					'ck',
					'ck-pagination-view-line',
					bindTemplate.if('isVisible', 'ck-hidden', isVisible => !isVisible)
				],
				style: {
					left: bindTemplate.to('left', value => toPx(value)),
					top: bindTemplate.to('top', value => toPx(value)),
					width: bindTemplate.to('width', value => toPx(value))
				}
			}
		});
	}
}

