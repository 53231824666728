import axios from 'axios'
import Swal from 'sweetalert2'
import Ajax from './Ajax'
import { logOutUser } from './helpers'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.baseURL = process.env.REACT_APP_API_URI

// Request interceptor
// /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["config"] }] */
axios.interceptors.request.use(config => {
    const token = Ajax.getTokenFromStorage("token")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    // request.headers['X-Socket-Id'] = Echo.socketId()

    return config
})

// Response interceptor
axios.interceptors.response.use(({ data }) => Promise.resolve(data.messages), async error => {
    let message = {
        title: 'Error Connection',
        text: 'Please check your internet connection'
    }

    let result = { // throw original result
        message: error?.messages || ''
    }

    let code = 500

    if ((error.response && error.response.data)) {
        const { data, status } = error.response // , statusText

        result = data.messages
        code = status

        switch (status) {
            case 400: { // bad request
                message = {
                    title: 'Oops!', // 'Invalid Credentials / Payload',
                    text: data.messages.message
                }
                break
            }
            case 401: {
                message = {
                    title: 'Session Expired', // 'Session Expired',
                    text: 'Due to inactivity, please log in again'
                }
                break
            }
            case 403: {
                message = {
                    title: 'Forbidden Page', // 'Unauthorize Access',
                    text: 'You are attempting to access a restricted page.'
                }
                break
            }
            case 404: {
                message = {
                    title: 'Page Not Found',
                    text: 'You are attempting to access a unknown page.'
                }
                break
            }
            case 413: {
                message = {
                    title: 'Attachment Too Large',
                    text: 'Please limit the size of your files.'
                }
                break
            }
            case 422: { // unprocessable
                message = {
                    title: 'Validation Error', // 'Invalid Data Format',
                    text: 'Your inputs seem to be incorrect or incomplete, please check and try again.'
                }
                // return Promise.reject(new AxiosError(result, code))
                break
            }
            case 429: { // many request
                message = {
                    title: 'Too Many Request',
                    text: 'Please limit reloading the page'
                }
                break
            }
            default: {
                message = {
                    title: 'Oops!', // 'Server Error'
                    text: 'Something went wrong, please contact your System Administrator'
                }
            }
        }
    } else {
        if (navigator.onLine) {
            message = {
                title: 'Oops!', // 'Server Error'
                text: 'Something went wrong, please contact your System Administrator'
            }
        }
    }

    return Swal.fire({
        title: message.title,
        html: `<h6>${message.text}</h6>`,
        confirmButtonText: 'Dismiss',
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        customClass: {
            title: 'h4 p-3 text-danger border-bottom',
            htmlContainer: 'px-2 py-5 m-0',
            actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0 my-0',
            confirmButton: 'btn btn-sm btn-danger my-1'
        },
        width: '25rem'
        // width: '25rem'
    }).then(() => {
        if ([401, 403].includes(code)) {
            logOutUser();
        }
        
        if (result) {
            return Promise.reject(
                Object.assign(result, {
                    required: result?.required || null
                })
            );
        }

        return Promise.reject({
            required: null
        })
    })
})
