import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import LoadingImage from "../Component/Quill/ImageUploader/blots";

import Compressor from 'compressorjs';

const readAndUploadFile = async (file, quill, quillRange, options) => {
    if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
            quill.focus();
            quillRange = await quill.getSelection();

            const encoded = reader.result;

            return new Compressor(file, {
                quality: file.size <= 1000000 ? 1 : 0.2,
                convertSize: file.size <= 1000000 ? file.size : file.size / 3,
                mimeType: file.type,
                success: async compressedImage => {

                    setTimeout(async () => {
                        try {
                            quill.insertEmbed(
                                quillRange.index,
                                LoadingImage.blotName,
                                encoded,
                                "user"
                            );

                            quill.focus();
                            quillRange = await quill.getSelection();

                            quillRange = await options.upload(
                                compressedImage,
                                quill,
                                quillRange
                            );
                        } catch (error) {
                            quill.focus();
                            quillRange = await quill.getSelection();
                        }
                    }, 500);
                }
            });
        };

        reader.readAsDataURL(file);
    }

    return await quillRange;
};


Quill.register(
    "modules/WAImage",
    function (quill, options) {

        let quillRange = quill.getSelection();
        if (options?.clipboard) {
            quill.root.addEventListener(
                "paste",
                async (evt) => {

                    let clipboard = evt.clipboardData || window.clipboardData;

                    // IE 11 is .files other browsers are .items
                    if (clipboard && (clipboard.items || clipboard.files)) {

                        let items = clipboard.items || clipboard.files;
                        const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

                        for (let i = 0; i < items.length; i++) {
                            if (IMAGE_MIME_REGEX.test(items[i].type)) {
                                let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
                                if (file) {
                                    evt.preventDefault();
                                    quill.focus();
                                    quillRange = await quill.getSelection();
                                    if (options.clipboard && typeof options.upload === 'function') {
                                        quillRange = await readAndUploadFile(
                                            file,
                                            quill,
                                            quillRange,
                                            options
                                        );
                                    } else {
                                        quill.deleteText(quillRange.index, 3, "user");
                                    }

                                }
                            }
                        }

                    }

                },
                false
            );
        }

        // quill.on('text-change', (delta, oldDelta, source) => {

        //     console.log(delta, oldDelta, source)
        // });
    }
);

Quill.register("modules/imageUploader", function (quill, options) {
    if (typeof options?.upload === "function") {
        const toolbar = quill.getModule("toolbar");
        quill.focus();

        let quillRange = quill.getSelection();
        let fileHolder = document.getElementById("react-quill-image-upload");
        if (!fileHolder) {
            fileHolder = document.createElement("input");
            fileHolder.setAttribute("id", "react-quill-image-upload");
            fileHolder.setAttribute("type", "file");
            fileHolder.setAttribute("accept", "image/*");
            fileHolder.setAttribute("style", "visibility:hidden");
            fileHolder.onchange = async function (evt) {
                const file = evt.target.files[0];
                quill.focus();
                quillRange = await quill.getSelection();
                setTimeout(async () => {
                    quillRange = await readAndUploadFile(
                        file,
                        quill,
                        quillRange,
                        options
                    );
                }, 500);
            };
            document.body.appendChild(fileHolder);
        }

        quill.root.addEventListener(
            "paste",
            async (evt) => {
                let clipboard = evt.clipboardData || window.clipboardData;

                // IE 11 is .files other browsers are .items
                if (clipboard && (clipboard.items || clipboard.files)) {
                    let items = clipboard.items || clipboard.files;
                    const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp|jpg)$/i;

                    for (let i = 0; i < items.length; i++) {
                        if (IMAGE_MIME_REGEX.test(items[i].type)) {
                            let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
                            if (file) {
                                evt.preventDefault();
                                quill.focus();
                                quillRange = await quill.getSelection();
                                quillRange = await readAndUploadFile(
                                    file,
                                    quill,
                                    quillRange,
                                    options
                                );
                            }
                        }
                    }
                }
            },
            false
        );

        toolbar.addHandler("image", async function () {
            quill.focus();
            quillRange = await quill.getSelection();
            fileHolder.click();
        });
    }

    // function getImgUrls(delta) {
    //   return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
    // }

    // quill.on("text-change", function (delta, oldContents) {
    //   const inserted = getImgUrls(delta);
    //   const deleted = getImgUrls(quill.getContents().diff(oldContents));
    //   inserted.length && console.log('insert', inserted)
    //   deleted.length && console.log('delete', deleted)
    // })
});

Quill.register("modules/imageResize", ImageResize);

Quill.register("modules/counter", function (quill, options) {
    const container = document.querySelector(options.container);
    quill.on("text-change", function () {
        const text = quill.getText();
        if (quill.getLength() >= options.limit) {
            quill.deleteText(options.limit, quill.getLength());
            container.innerText = `${quill.getLength() - 1} / ${options.limit} characters"`;
        } else {
            if (options.unit === "word") {
                container.innerText = `${text.split(/\s+/).length} words"`;
            } else {
                container.innerText = `${quill.getLength() - 1} / ${options.limit} characters"`;
            }
        }
    });
});
