import moment from "moment";
import { Col, Row } from "react-bootstrap";

export default function MailContainer({ workApproval, children }) {
	return (
		<Col xs={12} className="pb-5">
			<div className="d-flex flex-column">
				<div className="p-5" style={{ border: "2px dashed #c9c2b1"}}>

					<Row className="col-12">
						<Col xs={12} className="pb-2">
							<Row>
								<Col xs={3} md={2}>
									Date
								</Col>
								<Col xs={9} md={10}>
									: {moment(workApproval.target_date).format('MMMM D, Y')}
								</Col>
							</Row>
						</Col>
						<Col xs={12} className="pb-2">
							<Row>
								<Col xs={3} md={2}>
									To
								</Col>
								<Col xs={9} md={10}>
									: {workApproval.mail_to}
								</Col>
							</Row>
						</Col>
						<Col xs={12} className="pb-2">
							<Row>
								<Col xs={3} md={2}>
									From
								</Col>
								<Col xs={9} md={10}>
									: {workApproval.mail_from}
								</Col>
							</Row>
						</Col>
						<Col xs={12} className="pb-2">
							<Row>
								<Col xs={3} md={2}>
									Subject
								</Col>
								<Col xs={9} md={10}>
									: {workApproval.subject}
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div style={{ border: "2px dashed #c9c2b1", borderTop: 'none' }}>
					{children}
				</div>
			</div>
		</Col>
	);
}
