import { Button } from "react-bootstrap";

export default function TopButtonsDashboard({ forum, onSelect, isSubmitting }) {
    return (
			<Button
				variant="link"
				className="text-dark text-hover-primary"
				disabled={isSubmitting}
            onClick={() => onSelect(forum, () => {
                    window.location.href = "/system/forum-v2/post/normal/";
                })}
			>
				<svg className="h-25px w-25px me-2">
					<use xlinkHref="#icon-top_post_richtext"></use>
				</svg>
				Post
			</Button>
		);
}