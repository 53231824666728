import {
    Alignment,
    Autoformat,
    Bold,
    Italic,
    Strikethrough,
    Subscript,
    Superscript,
    Underline,
    BlockQuote,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    // FontFamily,
    FontSize,
    Heading,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
    Indent,
    IndentBlock,
    Link,
    List,
    ListProperties,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersEssentials,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    ImageInsert,
    ImageBlockEditing,
    ImageInline,
    Style,
    GeneralHtmlSupport,
    WordCount
} from 'ckeditor5';

import Pagination from './pagination/pagination';
// import { Pagination } from 'ckeditor5-premium-features'

import { AutoPageBreak } from './AutoPageBreak';
import { ImageUploader } from './ImageUploader';
import { Print } from './Print';

const plugins = [
    // TEXT
    Indent,
    IndentBlock,
    Italic,
    Paragraph,
    RemoveFormat,
    TextTransformation,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    Alignment,
    Autoformat,
    BlockQuote,
    Bold,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontSize,
    Heading,
    // IMAGE
    ImageInsert,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    // Base64UploadAdapter,
    PictureEditing,
    ImageBlockEditing,
    ImageInline,
    ImageUploader,
    //MISC
    Style,
    GeneralHtmlSupport,
    Link,
    List,
    ListProperties,
    // MediaEmbed,
    Mention,
    PageBreak,
    HorizontalLine,
    PasteFromOffice,
    Essentials,
    WordCount,
    // CloudServices,
    SpecialCharacters,
    SpecialCharactersEssentials,
    // TABLE
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    // CUSTOM
    Print,
    AutoPageBreak,
    Pagination
]

const toolbars = {
    shouldNotGroupWhenFull: false,
    items: [
        'undo',
        'redo',
        'PageNavigation',
        'print',
        '|',
        // 'PreviousPage',
        // 'NextPage',
        '|',
        // --- Font formatting ------------
        'heading',
        'fontSize',
        // 'fontFamily',
        '|',

        // --- Basic styles and inline formatting ------------
        'bold',
        'italic',
        'underline',
        {
            label: 'Basic Styles',
            icon: 'text',
            items: [
                'strikethrough',
                'superscript',
                'subscript',
            ],
        },
        'removeFormat',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        // --- Lists and indentation ------------
        'bulletedList',
        'numberedList',
        // --- Block-level formatting ------------
        '|',
        'outdent',
        'alignment',
        'indent',
        // 'multilevelList',
        // 'selectAll',
        '|',
        'insertImage',
        'insertTable',
        '|',
        // --- "Insertables" ------------
        'link',
        'blockQuote',
        'horizontalLine',
        'specialCharacters',
        '|',
        'findAndReplace',
        'pageBreak',
        'autoPageBreak'
    ],
}

const tableConfig = {
    contentToolbar: [
        'toggleTableCaption',
        '|',
        'tableRow',
        'tableColumn',
        'tableProperties',
        '|',
        'mergeTableCells',
        'tableCellProperties',
    ]
}

const imageConfig = {
    insert: {
        integrations: [
            'upload',
            'url'
        ]
    },
    toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:wrapText',
        '|',
        'resizeImage:117',
        'resizeImage:312',
        'resizeImage:original',
        // 'resizeImage:custom'
    ],
    resizeOptions: [
        {
            name: 'resizeImage:original',
            value: null,
            icon: 'original'
        },
        {
            name: 'resizeImage:117',
            value: '117',
            icon: 'small'
        },
        {
            name: 'resizeImage:312',
            value: '312',
            icon: 'medium'
        },
        // {
        //     name: 'resizeImage:custom',
        //     value: 'custom',
        //     icon: 'custom'
        // }
    ],
    resizeUnit: 'px',
    upload: {
        types: ['png', 'jpg', 'jpeg', 'webp']
    }
}

export {
    plugins,
    toolbars,
    tableConfig,
    imageConfig
}
