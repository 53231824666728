import axios from "axios"
import { objectToUrl } from "../../../../Utility/Plugins";

const PageAccessService = {
    get(payload = {}) {
        return axios.get(`admin/user/pages?${objectToUrl(payload)}`)
    },

    lists(payload = {}) {
        return axios.get(`admin/user/pages/access?${objectToUrl(payload)}`)
    },

    post(payload) {
        return axios.post('admin/user/pages', payload);
    },

    posts(payload) {
        return axios.post('admin/user/pages/updates', payload);
    },

    put(payload) {
        return axios.put(`admin/user/pages/${payload.id}`, payload);
    },

    delete(payload) {
        return axios.delete(`admin/user/pages/${payload.id}`, {
            data: payload
        });
    }
}

export {
    PageAccessService
}