import { Container} from "react-bootstrap";

export default function Success () 
{
    return (
        <Container
            className="vh-100 d-flex justify-content-center align-items-center flex-column"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{
                    background: 'none',
                    display: 'block',
                    shapeRendering: 'auto'
                }}
                width="200px"
                height="200px"
                
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="0"
                    fill="none"
                    stroke="#bcbe4f"
                    strokeWidth="2"
                >
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        values="0;40"
                        keyTimes="0;1"
                        keySplines="0 0.2 0.8 1"
                        calcMode="spline"
                        begin="0s"
                    />
                    <animate
                        attributeName="opacity"
                        repeatCount="indefinite"
                        dur="1s"
                        values="1;0"
                        keyTimes="0;1"
                        keySplines="0.2 0 0.8 1"
                        calcMode="spline"
                        begin="0s"
                    />
                </circle>
                <circle
                    cx="50"
                    cy="50"
                    r="0"
                    fill="none"
                    stroke="#0a7142"
                    strokeWidth="2"
                >
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        values="0;40"
                        keyTimes="0;1"
                        keySplines="0 0.2 0.8 1"
                        calcMode="spline"
                        begin="-0.5s"
                    />
                    <animate
                        attributeName="opacity"
                        repeatCount="indefinite"
                        dur="1s"
                        values="1;0"
                        keyTimes="0;1"
                        keySplines="0.2 0 0.8 1"
                        calcMode="spline"
                        begin="-0.5s"
                    />
                </circle>
            </svg>
            <p className="text-center h2 font-weight-bolder pt-2">
                Aljay Single Sign On
            </p>
            <p className="text-center h6 font-weight-bolder pt-2">
                Please wait while authenticating . . .
            </p>
        </Container>
    );
}   