const ComponentBlocker = ({ children, variant = 'primary', color = 'white', className, blocked = false }) => {
    return (
        <div className={`${className} ${blocked ? 'overlay overlay-block' : ''}`}>
            <div className="card-body p-0">
                <div className="overlay-wrapper">
                    {children}
                </div>
                <div
                    className={`no-select ${blocked ? `overlay-layer bg-${variant} rounded-3` : 'd-none'}`}
                    style={{ zIndex: 50 }}
                >
                    <div className="d-flex flex-column">
                        <i className={`fs-2x fas fa-spinner fa-spin text-${color}`}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentBlocker