/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react"

const BrandContext = createContext({
    setMobile: _mobile => { },
    setDesktop: _desktop => { },
    setIcon: _icon => { }
})

const BrandProvider = ({ children }) => {
    const [mobile, setMobile] = useState("")
    const [desktop, setDesktop] = useState("")
    const [icon, setIcon] = useState("")

    const value = {
        mobile,
        setMobile,
        desktop,
        setDesktop,
        icon,
        setIcon
    }
    return (
        <BrandContext.Provider value={value}>
            {children}
        </BrandContext.Provider>
    )
}

function useBrand() {
    return useContext(BrandContext)
}

const Brand = ({ desktop, mobile, icon }) => {
    const { setMobile, setDesktop, setIcon } = useBrand()

    useEffect(() => {
        if (desktop) {
            setDesktop(desktop)
        }
        return () => {
            setDesktop("")
        }
    }, [desktop])

    useEffect(() => {
        if (mobile) {
            setMobile(mobile)
        }
        return () => {
            setMobile("")
        }
    }, [mobile])

    useEffect(() => {
        if (icon) {
            setIcon(icon)
        }
        return () => {
            setIcon("")
        }
    }, [icon])

    return <></>
}

export { Brand, BrandProvider, useBrand }
