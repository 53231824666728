import axios from "axios"
import { objectToUrl } from "../../../Utility/Plugins";

const MyRequestService = {
    get(payload = {}) {
        return axios.get(`work-approval/my-requests?${objectToUrl(payload)}`);
    },

    show(payload = {}) {
        return axios.get(`work-approval/my-requests/${payload.id}?${objectToUrl(payload)}`);
    },

    send(payload) {
        return axios.post('work-approval/my-requests/send', payload);
    },

    renotify(payload) {
        return axios.post('work-approval/my-requests/resend', payload);
    },

    sendToSpecific(payload, progressCallback = null) {
        return axios.post('work-approval/my-requests/send-to-specific', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent
                const progress = Math.round((loaded * 100) / total).toFixed(2)
                if (typeof progressCallback === 'function') {
                    progressCallback(progress, loaded, total)
                }
            }
        });
    },

    post(payload) {
        return axios.post('work-approval/my-requests', payload);
    },

    put(payload) {
        return axios.put(`work-approval/my-requests/${payload.id}`, payload);
    },

    cancel(payload) {
        return axios.delete(`work-approval/my-requests/${payload.id}`, {
            data: payload
        });
    }
}

export {
    MyRequestService
}