
export default function Footer ({title = "", href = "#", lists = []})
{
    return (
        <div className="footer mt-auto py-4 px-5 order-3">
            <div className="d-flex justify-content-between align-items-center">
                <div className="text-dark">
                    <span className="fs-7 text-muted fw-bold me-2">2021 ©</span>
                    <b className="fs-7 text-gray-800 text-hover-primary">{title}</b>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold">
                    {/* {
                        lists.length > 0 ?
                        lists.map((list, i) => {
                            return (
                                <li className="menu-item" key={i}>
                                    <Link to={list.href} className="menu-link fs-7 px-2">{list.title}</Link>
                                </li>
                            );
                        }) : ""
                    } */}
                </ul>
            </div>
        </div>
    );
}