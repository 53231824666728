import React, { createRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MultiSelect from "../../../../Utility/MultiSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import { ReactQuillToolbars } from "../../../../Utility/Plugins";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import produce from "immer";
import { MyRequestService } from "../../services/my-request.service";
import Alert from "../../../../Utility/sweet-alert";
import { UserAccountService } from "../../../Admin/services/users/account.service";

const sendValidation = validate.object({
	mail_from: validate.string().required().label("Your email"),
	mail_to: validate.array().min(1).required().label("Employee"),
	subject: validate.string().required().label("Subject"),
	letter: validate.string().required().label("Letter"),
	mail_from_name: validate.string().required().label("Your name"),
}).required();

function WithUseCases({ workApproval, approver, files, comments }) {

	const commentForm = useForm({
		resolver: yupResolver(sendValidation),
		defaultValues: {
			created_by: approver.code,
			mail_from: approver.email,
			token: workApproval.token
		}
	});

	return (
		<SendToSpecificContainer
			workApproval={workApproval}
			form={commentForm}
			approver={approver}
			files={files}
			comments={comments}
		/>
	);
}

class SendToSpecificContainer extends React.Component {

	refLetter = createRef(null);

	constructor() {
		super();
		this.swal = new Alert();
		this.state = {
			fetchingUsers: false,
			submitting: false,
			modalOpen: false,
			approvers: []
		}
	}

	render() {
		return (
			<>
				{
					String(this.props.workApproval.created_by) === String(this.props.approver.code) && (
						["APPROVED", "REJECTED"].includes(this.props.workApproval.status) && (
							<Button
								size="sm"
								variant="primary"
								className="align-self-center me-2"
								disabled={this.state.fetchingUsers || this.state.submitting}
								onClick={
									() => this.setState({
										modalOpen: true
									})
								}
							>
								Send to Specific
							</Button>
						)
					)
				}
				<Modal
					size="lg"
					fullscreen="sm-down"
					className="bg-gray-100"
					backdrop={false}
					keyboard={false}
					show={this.props.approver && this.state.modalOpen}
					onHide={() => this.props.form.reset()}
					onShow={() => this.onCreateSendToSpecific()}
				>
					<Form
						noValidate
						className="modal-content"
						onSubmit={
							this.props.form.handleSubmit(
								validated => this.onSubmit(validated)
							)
						}
					>
						<Modal.Header>
							<Modal.Title>
								SEND TO SPECIFIC PERSON
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group className="col-12 mb-5">
								<Form.Label>Employee</Form.Label>
								<MultiSelect
									name="mail_to"
									className="border border-gray-300"
									options={this.state.approvers}
									control={this.props.form.control}
									values={this.state.selectedApprovers}
									errors={this.props.form.formState.errors}
									onChanged={
										(options, onChange) => {
											const selected = options?.map(
												(option) => option.value
											);

											this.setState({
												selectedApprovers: selected
											});

											return onChange(selected);
										}
									}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{this.props.form.formState.errors.mail_to?.message}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="col-12 mb-5">
								<Form.Label>Subject</Form.Label>
								<Form.Control
									{...this.props.form.register("subject")}
									className="border border-gray-300"
									placeholder="Type here . . ."
									readOnly={this.state.fetchingUsers}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{this.props.form.formState.errors.subject?.message}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="col-12 mb-5    ">
								<Form.Label>Letter</Form.Label>
								<ReactQuill
									ref={this.refLetter}
									placeholder="Compose a letter . . . "
									className="editor-height border border-gray-300 rounded-2"
									readOnly={this.state.fetchingUsers}
									value={this.props.form.watch("letter")}
									modules={{ toolbar: ReactQuillToolbars }}
									onChange={(value) =>
										this.props.form.setValue("letter", value === "<p><br></p>" ? "" : value)
									}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{this.props.form.formState.errors.letter?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer className="d-flex justify-content-between">
							<div>
								<Button
									size="sm"
									type="submit"
									className="me-1"
									variant="primary"
									disabled={this.state.fetchingUsers || this.state.submitting}
								>
									SEND
								</Button>
							</div>
							<Button
								variant="danger"
								size="sm"
								disabled={this.state.fetchingUsers || this.state.submitting}
								onClick={
									() => this.setState({
										modalOpen: false
									})
								}
							>
								CLOSE
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</>
		);
	}

	getUsers() {
		return new Promise(resolve => {
			this.setState({
				fetchingUsers: true
			}, () => {
				UserAccountService.lists({ active: 1 }).then(users => {
					this.setState(
						prev => produce(prev, state => {
							state.approvers = users.map(
								(row) => ({
									user: row,
									label: `${row.last_name}, ${row.first_name} ${row.middle_name}`,
									value: row.email
								})
							)
							state.fetchingUsers = false
						})
					);
				}).catch(() => {
					this.setState(
						prev => produce(prev, state => {
							state.approvers = []
							state.fetchingUsers = false
						})
					);
				}).finally(resolve);
			});
		});
	}

	onCreateSendToSpecific() {
		this.getUsers().finally(() => {
			this.setState({
				selectedApprovers: []
			}, () => {
				this.props.form.setValue("mail_from", this.props.approver.email);
				this.props.form.setValue(
					"mail_from_name",
					`${this.props.approver.name.last}, ${this.props.approver.name.first} ${this.props.approver.name?.middle || ''}`
				);
				this.props.form.setValue("subject", "");
				this.props.form.setValue("letter", "");
			});
		});
	}

	onSubmit(workApproval) {

		const formData = new FormData();
		formData.append('token', this.props.workApproval.token);
		formData.append('subject', workApproval.subject);
		formData.append('letter', workApproval.letter);
		formData.append('mail_from', workApproval.mail_from);
		workApproval.mail_to.forEach((mailTo, key) => {
			formData.append(`mail_to[${key}]`, mailTo);
		});
		formData.append('mail_from_name', workApproval.mail_from_name);

		this.swal.confirm({
			html: `Send this to the selected Employees?`,
			confirmButtonText: 'Send',
			preConfirm: async () => await MyRequestService.sendToSpecific(formData, (_progress, loaded, total) => {
				Swal.update({
					title: 'Please Wait',
					html: `<div>
							<p>Uploading Work Approval as Attachment</p>
							<p>${parseFloat((loaded / total) * 100).toFixed(2)} / 100.00 %</p>
						<div>`,
					showConfirmButton: false
				});
			}).then(({ message }) => {
				this.swal.success(message).then(() => {
					this.setState({
						modalOpen: false
					});
				});
			}).catch(() => {

			})
		})
	}
}

export default WithUseCases