import React, { createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MailContainer from "./print-component/MailContainer";
import SignatureContainer from "./print-component/SignatureContainer";
import AttachmentContainer from "./print-component/AttachmentContainer";
import CommentContainer from "./print-component/CommentContainer";
import RemarkContainer from "./print-component/RemarkContainer";
import SendToSpecificContainer from "./print-component/SendToSpecificContainer";

import { Button, Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import {LoadingRipple, statusColor, validSignatories } from "./other/helpers";

import Alert from "../../../Utility/sweet-alert";
import { logOutUser } from "../../../Utility/helpers";
import { userProfilePath } from "../../../Utility/Plugins";
import CustomCKEditor from "../../../Component/Custom/ckeditor/CustomCKEditor";

import { MyRequestService } from "../services/my-request.service";
import { imageConfig, plugins, tableConfig, toolbars } from "../../../Component/Custom/ckeditor/plugins";
import RequestForm from './my-request-component/RequestForm';

function WithUseCases() {

	const urlParams = useParams();
	const navigate = useNavigate();

	return (
		<Container
			fluid
			className="font-lato vh-100 print-area text-dark font-lato px-md-20 py-5"
		>
			<PrintPreview
				urlParams={urlParams}
				navigate={navigate}
			/>
		</Container>
	)
}

class PrintPreview extends React.Component {
	
	refWALetter = createRef(null);
	refRequestForm = createRef(null);

	constructor() {
		super();
		this.swal = new Alert();
		this.state = {
			loading: false,
			submitting: false,
			modalOpen: false,
			approver: null,
			workApproval: null,
			comments: [],
			files: [],
			token: null
		}
	}

	componentDidMount() {
		this.setState(() => {
			const hasUserStorage = localStorage.getItem("user");
			if (hasUserStorage) {
				return {
					token: this.props.urlParams?.token || null,
					approver: JSON.parse(hasUserStorage)
				};
			}

			return {
				token: this.props.urlParams?.token || null
			}
		}, () => {
			if (this.state.approver && this.state.token) {
				this.getWorkApprovalRequest();
			} else {
				logOutUser();
			}
		});
	}

	render() {

		if (this.state.loading) {
			return (
				<div className="h-100 d-flex justify-content-center align-items-center">
					<LoadingRipple />
				</div>
			);
		}

		if (this.state.approver && this.state.workApproval) {
			return (
				<Row className="pb-5" style={{
					position: 'relative'
				}}>
					<Col
						xs={12}
						className="py-5 bg-white"
						style={{
							position: 'sticky',
							top: 0,
							zIndex: 100
						}}
					>
						<div className="d-flex justify-content-between flex-column flex-md-row">
							<div className="order-2 order-md-1">
								<Button
									size="sm"
									variant="warning"
									className="align-self-center me-2"
									disabled={this.state.submitting}
									onClick={
										() => {
											if (this.refWALetter.current) {
												this.refWALetter.current.onPrint();
											}
										}
									}
								>
									Print
								</Button>
								{
									String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
										["REQUESTED"].includes(this.state.workApproval.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center me-2"
												disabled={this.state.submitting}
												onClick={() => this.onSendRequest()}
											>
												Send Request
											</Button>
										)
									)
								}
								{
									String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
										["ON PROCESS"].includes(this.state.workApproval.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center me-2"
												disabled={this.state.submitting}
												onClick={() => this.onReSendRequest()}
											>
												Renotify
											</Button>
										)
									)
								}
								{
									String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
										["APPROVED", "REJECTED"].includes(this.state.workApproval.status) && (
											<SendToSpecificContainer
												workApproval={this.state.workApproval}
												approver={this.state.approver}
												comments={this.state.comments}
												files={this.state.files}
											/>
										)
									)
								}
								<Button
									size="md"
									variant="link"
									className={`fw-bolder fs-4 text-${statusColor(this.state.workApproval.status)}`}
								>
									{this.state.workApproval.status === "REQUESTED" ? "DRAFT" : this.state.workApproval.status}
								</Button>
							</div>

							<div className="d-flex ms-auto align-self-center order-1 order-md-2">

								{
									String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
										["REQUESTED"].includes(this.state.workApproval.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center me-2"
												disabled={this.state.submitting}
												onClick={() => {
													if (this.refRequestForm.current) {
														this.refRequestForm.current.onEditRequest(this.state.workApproval);
													}
												}}
											>
												Edit
											</Button>
										)
									)
								}

								{
									String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
										["REQUESTED", "ON PROCESS"].includes(this.state.workApproval.status) && (
											<Button
												size="sm"
												variant="danger"
												className="align-self-center me-2"
												disabled={this.state.submitting}
												onClick={() => this.onCancelRequest()}
											>
												Cancel
											</Button>
										)
									)
								}

								<Dropdown>
									<Dropdown.Toggle className="btn btn-dark btn-sm" as="button">
										Back
									</Dropdown.Toggle>
									<Dropdown.Menu className="py-0">
										<Dropdown.Item className="fs-4 py-5 mb-0 bg-primary text-white border border-white" style={{ borderRadius: '10px 10px 0px 0px' }}>
											<div className="d-flex flex-row-fluid align-items-center justify-content-center">
												<div>
													<div className="symbol symbol-35px cursor-pointer">
														<Image
															className="cursor-pointer"
															width={35}
															height={35}
															roundedCircle
															src={userProfilePath(this.state.approver.code)}
															onError={(e) =>
																(e.target.src = `/assets/media/avatars/blank.png`)
															}
														/>
													</div>
												</div>
												<div>
													<div className="px-3 text-start text-white text-uppercase fs-8">
														<strong>
															{this.state.approver.name.first} {this.state.approver.name.last} {this.state.approver.name.suffix}
														</strong>
													</div>
													<div className="px-3 text-start text-white fs-9">
														{this.state.approver.position.title}
													</div>
												</div>
											</div>
										</Dropdown.Item>
										<Dropdown.Divider className="my-0 py-0" />
										<Dropdown.Item
											className="fs-4 mb-0 py-2"
											href="/system/"
											onClick={() => {
												localStorage.removeItem("system")
											}}
										>
											<i className="fas fa-home w-25px"></i> Home
										</Dropdown.Item>
										<Dropdown.Divider className="my-1 py-0" />
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/work-approval/dashboard/">
											<i className="fas fa-leaf w-25px"></i> Dashboard
										</Dropdown.Item>
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/work-approval/inbox/">
											<i className="fas fa-inbox w-25px"></i> Inbox
										</Dropdown.Item>
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/work-approval/requests">
											<i className="fas fa-file-contract w-25px ms-1"></i>My Requests
										</Dropdown.Item>
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/work-approval/approvals">
											<i className="fas fa-file-signature w-25px ms-1"></i>Approval Requests
										</Dropdown.Item>
										<Dropdown.Divider className="my-1 py-0" />
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/user/profile/">
											<i className="fas fa-user w-25px ms-1"></i>Employee Profile
										</Dropdown.Item>
										<Dropdown.Item className="fs-4 text-danger py-2" style={{ borderRadius: '0px 0px 10px 10px' }} onClick={logOutUser}>
											<i className="fas fa-power-off w-25px ms-1 text-danger"></i>Log Out
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</Col>
					<Col
						xs={12}
					>
						<Row>
							<Col xs={12} md={8}>
								<Row>
									<MailContainer
										workApproval={this.state.workApproval}
									>
										<CustomCKEditor
											ref={this.refWALetter}
											value={this.state.workApproval.letter}
											enabled={false}
											config={
												{
													placeholder: 'Compose here . . .',
													label: 'Work Approval Letter',
													pagination: {
														// A4
														pageWidth: '794px',
														pageHeight: '1092px',
														pageMargins: {
															top: '20mm',
															bottom: '20mm',
															right: '12mm',
															left: '12mm'
														}
													},
													plugins: plugins,
													toolbar: toolbars,
													table: tableConfig,
													image: imageConfig,
													workApproval: () => this.getDocumentPrinting()
												}
											}
										/>
									</MailContainer>
									<SignatureContainer
										workApproval={this.state.workApproval}
										approver={this.state.approver}
										reloadRequest={
											() => {
												this.getWorkApprovalRequest()
											}
										}
									/>
								</Row>
							</Col>
							
							<Col
								xs={12}
								md={4}
								style={{
									position: 'sticky',
									top: 50
								}}
							>
								<Row>
									<AttachmentContainer
										workApproval={this.state.workApproval}
										setFiles={
											files => this.setState({
												files: files
											})
										}
									/>
									<CommentContainer
										workApproval={this.state.workApproval}
										approver={this.state.approver}
										setComments={
											comments => this.setState({
												comments: comments
											})
										}
									/>
									<RemarkContainer
										workApproval={this.state.workApproval}
									/>
								</Row>
							</Col>
						</Row>
					</Col>
					{
						String(this.state.workApproval.created_by) === String(this.state.approver.code) && (
							["REQUESTED"].includes(this.state.workApproval.status) && (
								<Col>
									<RequestForm
										ref={this.refRequestForm}
										user={this.state.approver}
										reloadRequests={
											() => this.getWorkApprovalRequest()
										}
									/>
								</Col>
							)
						)
					}
				</Row>
			);
		}

		return (
			<div className="h-100 d-flex flex-column justify-content-center align-items-center px-5">
				<h2 className="p-4 text-center">
					You're not allowed to view this Work Approval Request
				</h2>
				<Button
					size="sm" 
					variant="primary"
					href="/system/work-approval/dashboard"
				>
					Go to Dashboad
				</Button>
			</div>
		);
	}

	getWorkApprovalRequest() {
		this.setState({
			loading: true
		}, () => {
			MyRequestService.show({
				id: 0,
				token: this.state.token,
				approver_code: this.state.approver.code
			}).then(({ workApproval }) => {
				this.setState({
					workApproval: workApproval,
					loading: false
				})
			});
		})
	}

	onSendRequest() {
		const validApprover = validSignatories.find(
			({ order }) => Number(order) === 1
		);

		if (validApprover) {
			this.swal.confirm({
				html: () => { 
					if (this.state.files.length <= 0) {
						return `Send Work Approval Request without Attachments?`
					}
					return `Send Work Approval Request?`
				},
				confirmButtonText: 'Send',
				preConfirm: async () => {
					return await MyRequestService.send({
						token: this.state.workApproval.token
					}).then(({ message }) => {
						this.swal.success(message).then(() => {
							this.getWorkApprovalRequest();
						})
					})
				}
			})
		} else {
			this.swal.invalid('Approvers seem to be Invalid')
		}
	}

	onReSendRequest() {
		const validApprover = validSignatories.find(
			({ order }) => Number(order) === Number(this.state.workApproval.current_signature_level)
		);
		if (validApprover) {
			this.swal.confirm({
				html: `Renotify to all approvers from ${validApprover.value}?`,
				confirmButtonText: 'Renotify',
				preConfirm: async () => {
					return await MyRequestService.renotify({
						token: this.state.workApproval.token
					}).then(({ message }) => {
						this.swal.success(message)
					})
				}
			})
		} else {
			this.swal.invalid('Approvers seem to be Invalid')
		}
	}

	onCancelRequest() {
		this.swal.confirm({
			html: `Mark your request as Canceled?`,
			confirmButtonText: 'Mark as Canceled',
			customClass: {
				confirmButton: 'btn btn-sm btn-danger my-1'
			},
			preConfirm: async () => {
				return await MyRequestService.cancel({
					id: this.state.workApproval.id
				}).then(({ message }) => {
					this.getWorkApprovalRequest()
					this.swal.success(message)
				})
			}
		})
	}

	getDocumentPrinting() {
		const { mail_to, mail_from, subject, letter, approvers } = this.state.workApproval;

		return {
			mail_to,
			mail_from,
			subject,
			letter,
			approvers,
			comments: this.state.comments
		}
	}
	
}

export default WithUseCases