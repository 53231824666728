import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const currentFormatDefault = {
	format: (
		<iframe
			title="default"
			style={{ height: "90%", width: "100%" }}
			frameBorder="0"
		/>
	),
};

export default function FileViewer({ url, selected = 0 }) {

	const [selectedFile, setSelectedFile] = useState(selected);
	
	const [currentFormat, setCurrentFormat] = useState(currentFormatDefault);

	useEffect(() => {
		switch (url[selectedFile]?.type.split("/")[0]) {
			case "image":
				setCurrentFormat({
					format: (
						<img
							className="col-auto"
							style={{ height: "100%" }}
							alt={url[selectedFile].uri}
							src={url[selectedFile].uri}
						/>
					),
				});
				break;
			case "video":
				setCurrentFormat({
					format: (
						<div
							className="col-md-6 col-lg-7 d-flex justify-content-center"
							style={{ maxHeight: "400px" }}
						>
							<video
								className="col-12"
								controls
								src={url[selectedFile].uri}
							/>
						</div>
					),
				});
				break;
			case "audio":
				setCurrentFormat({
					format: <audio controls src={url[selectedFile].uri} />,
				});
				break;
			default:
				setCurrentFormat({
					format: (
						<iframe
							title={url[selectedFile].uri}
							style={{ height: "100%", width: "100%" }}
							src={`https://docs.google.com/viewer?url=${url[selectedFile].uri}&embedded=true&samesite=none`}
							frameBorder="0"
						/>
					),
				});
				break;
		}
	}, [selectedFile, url, setCurrentFormat]);

	return (
		<div className="h-100 col-12 py-5">
			<div className="py-3 d-flex flex-column justify-content-between align-items-center h-100">
				<div className="fs-3">
					{url[selectedFile]?.name}
				</div>
				<div
					className="d-flex justify-content-center h-75 py-2 col-12"
					style={{ overflowX: "auto" }}
				>
					{currentFormat.format}
				</div>
			</div>

			<div>
				<Pagination>
					{url?.map((row, i) => (
						<Pagination.Item
							active={i === selectedFile}
							onClick={() => {
								setSelectedFile(i);
							}}
							key={i}
						>
							{i + 1}
						</Pagination.Item>
					))}
				</Pagination>
			</div>
		</div>
	);
}
