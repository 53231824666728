// Main class definition
import { Plugin, env } from 'ckeditor5';

import {PaginationEditing} from './pagination-editing.js';
import {PaginationUI} from './pagination-ui.js';
import './css/pagination.css'


export default class Pagination extends Plugin {
    static get pluginName() {
        return 'Pagination';
    }

    static get requires() {
        return [PaginationEditing, PaginationUI];
    }

    constructor(editor) {
        super(editor);
        this.isEnabled = false;
    }

    init() {
        this.set({
            'pageNumber': 1,
            'totalPages': 1
        });

        const pPaginationEditing = this.editor.plugins.get(PaginationEditing);

        this.bind('pageNumber').to(pPaginationEditing);

        this.bind('totalPages').to(pPaginationEditing);

        if (env.isBlink || this.editor['config']['get']('pagination.enableOnUnsupportedBrowsers')) {
            this.editor.once('ready', () => {
                this.isEnabled = true;
            })
        } else {
            this.forceDisabled('browserCheck');
        }

    }

    scrollToPage(pageNumber) {
        this.editor.plugins.get(PaginationEditing).scrollToPage(pageNumber);
    }
}
