
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Aside from "../../Component/Aside/Aside";
import { AsideMenu, AsideMenuProvider } from "../../Component/Aside/AsideMenuProvider";
import { Brand, BrandProvider } from "../../Component/Wrapper/Header/BrandProvider";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import Wrapper from "../../Component/Wrapper/Wrapper";
// import { canNavigate } from "../../Utility/helpers";
import { generateSocketToken, getNotificationTokenFromStorage } from "../../Utility/NotificationHelper";
import socketClient from "socket.io-client";
import Ajax from "../../Utility/Ajax";
import { Container } from "react-bootstrap";
import { NoPost } from "./Page/utils/utils";
import { Alert } from "../../Utility/Plugins";

const View = ({ children, isSocketOpen }) => (
	<Suspense fallback={<></>}>
		{
			isSocketOpen ? children :
				<Container className="min-h-400px d-flex justify-content-center">
					{NoPost("SOCKET SERVER IS CURRENTLY DOWN")}
				</Container>
		}
	</Suspense>
)

export const ForumV2Wrapper = () => {

	const Home = lazy(() => import("./Page/Home"));
	const Profile = lazy(() => import("./Page/Profile"));
	const Me = lazy(() => import("./Page/Me"));
	const Search = lazy(() => import("./Page/Search"));
	const PostNormal = lazy(() => import("./Page/Post/PostNormal"));
	const PostView = lazy(() => import("./Page/Post/PostView"));
	const Channels = lazy(() => import("./Page/Channel/Channels"));
	const ChannelView = lazy(() => import("./Page/Channel/ChannelView"));
	const ManageChannel = lazy(() => import("./Page/Channel/ManageChannel"));
	const Monitoring = lazy(() => import("./Page/Monitoring"));

	const navigate = useNavigate();
	const location = useLocation();

	const [user, setUser] = useState(null);
	const [system, setSystem] = useState(null);
	const [pages, setPages] = useState(null);
	const [isSocketOpen, setSocketOpen] = useState(false);
	const [isNotificationGranted, setNotificationPermission] = useState(false);

	const socket = useRef({});

	useEffect(() => {
		if (Notification.permission === "granted") {
			setNotificationPermission(true);
		} else {
			Alert("Notification", "Please allow notification permission first, in order to access forum", "warning", async () => {
				localStorage.removeItem("system");
				localStorage.removeItem("pages");
				navigate("/system/");
			})
		}
	}, [location, navigate]);

	useEffect(() => {
		const userFromStorage = localStorage.getItem("user");
		if (userFromStorage) {
			setUser(JSON.parse(userFromStorage));
			const systemFromStorage = localStorage.getItem("system");
			if (systemFromStorage) {
				const currentSystem = JSON.parse(systemFromStorage);
				setSystem(currentSystem);
				if (location.pathname.split(`/`)[2] === currentSystem.href.split("/")[1]) {
					const pagesFromStorage = localStorage.getItem("pages");
					if (pagesFromStorage) {
						const currentPages = JSON.parse(pagesFromStorage);
						setPages(currentPages);
						// if (currentPages && currentPages.length > 0 && location && !canNavigate(currentPages, location)) {
						//     navigate("/system/");
						// }
					} else {
						navigate("/system/");
					}
				} else {
					navigate(`/system${currentSystem.href}`);
				}
			} else {
				navigate("/system/");
			}
		} else {
			navigate("/");
		}
	}, [isNotificationGranted, location, navigate]);

	const getUserName = (user) => {
		return `${user.name.first} ${user.name.middle} ${user.name.last}`
	}

	useEffect(() => {
		if (user?.code) {
			socket.current = socketClient(Ajax.env.SOCKET_ENDPOINT, {
				transports: ["websocket"],
				auth: {
					...generateSocketToken(user)
				}
			});
			socket.current.on("connect", async () => {
				try {
					const token = await getNotificationTokenFromStorage();
					if (token) {
						socket.current.emit("forum-notification", {
							token: token,
							user: user,
							room: "forum-post"
						});
						setSocketOpen(true);
					}
				} catch (error) {
					setSocketOpen(false);
				}
			});
			socket.current.on("disconnect", () => {
				setSocketOpen(false);
			});
			socket.current.on("connect_error", () => {
				setSocketOpen(false);
			});
		}
	}, [user, navigate]);

	return (
		user &&
		system &&
		pages &&
		isNotificationGranted && (
			<AsideMenuProvider>
				<AsideMenu lists={pages} />
				<BrandProvider>
					<Brand
						mobile={system.title}
						desktop={system.title}
						icon={system.icon}
					/>
					<BreadCrumbsProvider>
						<Routes>
							<Route
								element={
									<>
										<Aside footer={user} />
										<Wrapper />
									</>
								}
							>
								<Route
									path="*"
									exact
									index
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Home", href: `#` },
												]}
											>
												Home
											</BreadCrumbs>
											<Home
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="profile/:user_code"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Profile", href: `#` },
												]}
											>
												Profile
											</BreadCrumbs>
											<Profile
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="me"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Profile", href: `#` },
												]}
											>
												{getUserName(user)}
											</BreadCrumbs>
											<Me
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="search"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Search", href: `#` },
												]}
											>
												Search
											</BreadCrumbs>
											<Search
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="manage/monitoring"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Monitoring", href: `#` },
												]}
											>
												Monitoring
											</BreadCrumbs>
											<Monitoring
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="post/normal"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Create", href: `#` },
													{ title: "Post", href: `#` },
												]}
											>
												Create Post
											</BreadCrumbs>
											<PostNormal
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="post/normal/:channel_code/:post_code"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Edit", href: `#` },
													{ title: "Post", href: `#` },
												]}
											>
												Edit Post
											</BreadCrumbs>
											<PostNormal
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path=":channel_code/:post_code"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<PostView
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="channels/"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<BreadCrumbs
												lists={[
													{ title: system.title, href: `/system${system.href}` },
													{ title: "Channels", href: `#` },
												]}
											>
												Channels
											</BreadCrumbs>
											<Channels user={user} />
										</View>
									}
								/>
								<Route
									path="channel/:channel_code"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<ChannelView
												user={user}
												socket={socket.current}
											/>
										</View>
									}
								/>
								<Route
									path="manage/channel"
									exact
									element={
										<View isSocketOpen={isSocketOpen}>
											<ManageChannel user={user} />
										</View>
									}
								/>
							</Route>
						</Routes>
					</BreadCrumbsProvider>
				</BrandProvider>
			</AsideMenuProvider>
		)
	);
}