import { Plugin, SwitchButtonView } from "ckeditor5";

class AutoPageBreak extends Plugin {

    static get pluginName() {
        return 'AutoPageBreak';
    }

    init() {
        // Add a button to the component factory so it is available for the editor.
        this.editor.ui.componentFactory.add('autoPageBreak', locale => {
            const switchButtonView = new SwitchButtonView(locale);

            switchButtonView.set({
                class: 'fw-bolder',
                type: 'button',
                label: 'APB',
                tooltip: 'Auto Page Break',
                withText: true,
                isOn: true,
            });

            // Define the button behavior on press.
            switchButtonView.on('execute', () => {
                switchButtonView.isOn = !switchButtonView.isOn;

                if (switchButtonView.isOn) {
                    this._calculatePageBreaks();
                }
            });

            // Listen for content changes
            this.editor.model.document.on('change:data', () => {
                if (switchButtonView.isOn) {
                    this._calculatePageBreaks();
                }
            });

            return switchButtonView;
        });

        this.editor.editing.view.document.on('keydown', (_event, domEventData) => {
            // Check if the Tab key was pressed
            if (domEventData.keyCode === 9) { // 9 is the keyCode for Tab
                domEventData.preventDefault(); // Prevent default Tab behavior (focus change)

                // Insert tab character at the current selection
                this.editor.model.change(writer => {
                    const selection = this.editor.model.document.selection;
                    writer.insertText('     ', selection.getFirstPosition());
                });
            }
        });

    }

    _calculatePageBreaks() {

        const A4_HEIGHT = 1100;               // A4 height in pixels

        const BOTTOM_MARGIN = 80;
        const FIRST_PAGE_TOP_MARGIN = 140;    // Top margin for the first page
        const FIRST_PAGE_BOTTOM_MARGIN = 0; // Bottom margin for the first page

        const REST_PAGE_TOP_MARGIN = 0;      // Top margin for subsequent pages
        const REST_PAGE_BOTTOM_MARGIN = 0;   // Bottom margin for subsequent pages

        const FIRST_PAGE_HEIGHT = A4_HEIGHT - (BOTTOM_MARGIN + FIRST_PAGE_TOP_MARGIN + FIRST_PAGE_BOTTOM_MARGIN);
        const REST_PAGE_HEIGHT = A4_HEIGHT - (REST_PAGE_TOP_MARGIN + REST_PAGE_BOTTOM_MARGIN);

        const PAGE_BREAK_HEIGHT = 10;

        const root = this.editor.model.document.getRoot();
        // const rootView = this.editor.editing.mapper.toViewElement(root);
        // const rootDom = this.editor.editing.view.domConverter.mapViewToDom(rootView);

        this.editor.model.change(writer => {

            let pageBreaks = [], currentPage = 0;

            // Collect existing page breaks to remove them
            Array.from(root.getChildren()).filter(
                child => child.name === 'pageBreak'
            ).forEach(
                child => writer.remove(writer.createRangeOn(child))
            );

            const lastPageBreak = [];

            // Go through all child nodes of the document root
            for (const childElement of root.getChildren()) {

                const childView = this.editor.editing.mapper.toViewElement(childElement);
                if (!childView) {
                    continue;
                }

                const childDom = this.editor.editing.view.domConverter.mapViewToDom(childView);
                if (!childDom) {
                    continue;
                }

                const childRects = childDom.getBoundingClientRect();

                if (lastPageBreak.length <= 0) {
                    lastPageBreak.push(childRects);
                }

                const TOP_HEIGHT = lastPageBreak[lastPageBreak.length - 1].top;

                const BOTTOM_TO_TOP_HEIGHT = childRects.bottom - TOP_HEIGHT;
                const PAGE_HEIGHT = currentPage <= 0 ? FIRST_PAGE_HEIGHT : REST_PAGE_HEIGHT;

                const ADD_PAGE_BREAK_BEFORE = BOTTOM_TO_TOP_HEIGHT >= PAGE_HEIGHT;

                const ADD_PAGE_BREAK_AFTER = BOTTOM_TO_TOP_HEIGHT >= (PAGE_HEIGHT - PAGE_BREAK_HEIGHT);

                if (ADD_PAGE_BREAK_BEFORE) {

                    lastPageBreak.push(childRects);
                    pageBreaks.push(writer.createPositionBefore(childElement));
                    currentPage++;

                } else if (ADD_PAGE_BREAK_AFTER) {

                    lastPageBreak.push(childRects);
                    pageBreaks.push(writer.createPositionAfter(childElement));
                    currentPage++;

                }
            }

            // Insert the collected page breaks into the document
            pageBreaks.forEach(position => {
                if (root.getChild(position.offset)) {
                    writer.insertElement('pageBreak', position);
                }
            });
        });
    }
}

export { AutoPageBreak };
