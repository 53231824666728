import React, { createRef } from 'react';

import { Container } from 'react-bootstrap';
import { Context, ContextWatchdog, DecoupledEditor } from 'ckeditor5';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import { EncodeSignatures, validSignatories } from '../../../System/WorkApproval/pages/other/helpers';

import moment from 'moment';

import ComponentBlocker from '../../../Component/Custom/ComponentBlocker'

import 'ckeditor5/ckeditor5.css';
// import jsPDF from 'jspdf';

class CustomCKEditor extends React.Component {

    refCkEditor = createRef(null);

    constructor() {
        super();
        this.state = {
            isNotReady: true
        };
    }

    render() {

        return (
            <ComponentBlocker
                blocked={this.isNotReady}
                variant="light"
                color="dark"
            >
                <Container fluid className='px-0'>
                    <CKEditorContext
                        context={Context}
                        contextWatchdog={ContextWatchdog}
                    >
                        <div className="d-flex flex-column rich-editor">

                            {
                                this.props?.enabled && (
                                    <div
                                        className="wack-toolbar-container"
                                        style={{
                                            position: 'sticky',
                                            zIndex: 10,
                                            top: 0
                                        }}
                                    />
                                )
                            }

                            <div
                                className='w-100 py-0'
                                style={{
                                    overflowX: 'scroll',
                                    backgroundColor: '#eee',
                                    minHeight: 'calc(1092px / 2)'
                                }}
                            >
                                <CKEditor
                                    id="ckEditorID"
                                    ref={this.refCkEditor}
                                    editor={DecoupledEditor}
                                    config={this.props.config}
                                    disabled={!this.props?.enabled}
                                    data={this.props.value}
                                    onReady={
                                        editor => {
                                            if (this.props?.enabled) {
                                                const enableWordCount = editor.config.get('wordCount');

                                                if (enableWordCount) {
                                                    const wordCountPlugin = editor.plugins.get('WordCount');

                                                    const toolbarContainer = document.querySelector('.wack-toolbar-container');
                                                    const wordCountWrapper = document.getElementById('word-count');

                                                    toolbarContainer.appendChild(editor.ui.view.menuBarView.element);
                                                    toolbarContainer.appendChild(editor.ui.view.toolbar.element);

                                                    // toolbarContainer.firstElementChild.classList.add('ck-toolbar_grouping')
                                                    wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
                                                }

                                                editor.disableReadOnlyMode('feature-id');
                                            } else {
                                                editor.enableReadOnlyMode('feature-id');
                                            }
                                            
                                            editor.listenTo(editor.ui.view, 'printWorkApprovalDocument', () => {
                                                this.onPrint();
                                            });

                                            this.setState({
                                                isNotReady: false
                                            });

                                        }
                                    }
                                    onChange={
                                        (_evt, editor) => {

                                            if (!this.props?.enabled || typeof this.props?.onChange !== 'function') {
                                                return;
                                            }

                                            this.props.onChange(editor.getData(), editor);
                                        }
                                    }
                                />
                            </div>

                            {
                                this.props?.enabled && (
                                    <div className='d-flex justify-content-end mt-3'>
                                        <div id='word-count'></div>
                                    </div>
                                )
                            }
                        </div>
                    </CKEditorContext>
                    <div>
                        <iframe
                            id="print-data-container"
                            aria-hidden="true"
                            tabIndex="-1"
                            title='1'
                            style={{
                                position: 'absolute',
                                columns: '794px',
                                width: '794px',
                                height: '1092px',
                                marginLeft: '-794px',
                                marginTop: '-1092px',
                                border: 0,
                                padding: 0,
                                whiteSpace: 'nowrap',
                                clipPath: 'inset(100%)',
                                clip: 'rect(0 0 0 0)',
                                overflow: 'hidden'
                            }}
                        />
                    </div>
                </Container>
            </ComponentBlocker>
        );
    }

    onPrint() {

        const workApprovalConfig = this.refCkEditor.current.editor.config.get('workApproval');

        if (!workApprovalConfig) {
            return;
        }

        if (typeof workApprovalConfig !== 'function') {
            return;
        }

        const workApproval = workApprovalConfig();

        if (!workApproval?.approvers) {
            return;
        }

        if (workApproval.approvers?.length <= 0) {
            return;
        }

        const signatories = EncodeSignatures(
            workApproval.approvers,
            (value, _) => value,
            validSignatories
        ).signatureInputs;

        const styles = Array.from(document.querySelectorAll('style')).map(style => style.outerHTML).join('\n');
        
        const staticLinks = Array.from(document.querySelectorAll('link')).filter(
            link => String(link.href).indexOf('/static/') >= 0
        ).map(staticLink => staticLink.outerHTML).join('\n');

        const renderHTML = (
            `
                <html class="printing">
                    <head>
                        <title>${workApproval.subject}</title>
                        <link rel="stylesheet" href="${window.location.origin}/assets/plugins/global/plugins.bundle.css" />
                        <link rel="stylesheet" href="${window.location.origin}/assets/css/style.bundle.css" />
                        <link rel="stylesheet" href="${window.location.origin}/assets/css/printing.css" />
                        <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' />
                        ${staticLinks}
                        ${styles}
                    </head>
                    <body class="printing">
                        <table class="w-100">
                            <tbody>
                                <tr id="editor-header" class="w-100 editor-header">
                                    <td>
                                        ${this.tHeader(workApproval)}
                                    </td>
                                </tr>
                                <tr class="w-100 editor-data">
                                    <td>
                                        <div id="editor-data" class="ck ck-content">
                                            ${this.refCkEditor.current.editor.getData()}
                                            ${this.tComments(workApproval.comments)}
                                            ${this.tRemarks(signatories)}
                                            ${this.tSignatories(signatories)}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot id="editor-footer" class="page-footer">
                                <tr>
                                    <td>
                                        <div class="footer-wrapper">
                                            <div class="footer-label">${process.env.REACT_APP_COMPANY_TITLE || ''}</div>
                                            <div class="footer-label">${process.env.REACT_APP_COMPANY_ADDRESS || ''}</div>
                                            <div class="footer-label">${process.env.REACT_APP_COMPANY_NUMBER || ''}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        <script src="${window.location.origin}/assets/js/printing.js"></script>
                    </body>
                </html>
            `
        );

        const iframe = document.getElementById('print-data-container');
        iframe.srcdoc = renderHTML;

        // console.log({
        //     iframe: iframe.contentWindow.document
        // })

        // const pdf = new jsPDF({
        //     precision: 1
        // });

        // pdf.html(iframe.contentWindow.document, {
        //     callback: pdfDocument => {
        //         console.log(pdfDocument)

        //         window.open(pdfDocument.output('bloburi'))
        //     }
        // })
    }

    tHeader(workApproval) {
        const version = Math.floor(Math.random() * 100);
        const targetDate = workApproval?.target_date ? moment(workApproval.target_date).format('MMMM DD, YYYY') : moment().format('MMMM DD, YYYY');

        return (
            `
                <div class="page-header">
                    <div style="align-self: end">
                        <img
                            src='${process.env.REACT_APP_API_URI}/company/company-logo-print.png?v=${version}'
                            alt='test'
                            style="height: 50px"
                        />
                    </div>
                    <table class="w-100">
                        <tbody>
                            <tr>
                                <td style="width: 10%;">
                                    <b>Date</b>
                                </td>
                                <td style="width: 90%;">: ${targetDate}</td>
                            </tr>
                            <tr>
                                <td style="width: 10%;">
                                    <b>To</b>
                                </td>
                                <td style="width: 90%;">: ${workApproval.mail_to}</td>
                            </tr>
                            <tr>
                                <td style="width: 10%;">
                                    <b>From</b>
                                </td>
                                <td style="width: 90%;">: ${workApproval.mail_from}</td>
                            </tr>
                            <tr>
                                <td style="width: 10%;">
                                    <b>Subject</b>
                                </td>
                                <td style="width: 90%;">: ${workApproval.subject}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            `
        );
    }

    tRemarks(signatories) {
        const approverRemarks = signatories.filter(
            signatory => signatory.approvers.filter(
                approver => approver.remarks
            ).length > 0
        );

        if (approverRemarks.length > 0) {
            return (
                `
                    <p><br data-cke-filler="true"></p>
                    <p class="approver-title">Remarks</p>
                    ${
                        approverRemarks.map(
                            signatory => signatory.approvers.filter(approver => approver.remarks).map(
                                approver => (
                                    `
                                        <p class="approver-title">
                                            ${approver.first_name} ${approver.last_name}
                                        </p>
                                        <p>
                                            ${approver.remarks}
                                        </p>
                                        <p style="border-bottom: 1px solid gray; text-align: right;">
                                            ${moment(signatory.status_at).format('MMMM d, Y - hh:mm A')}
                                        </p>
                                    `
                                )
                            ).join('')
                        ).join('')
                    }
                `
            );
        }

        return '';
    }

    tComments(comments) {
        if (comments.length > 0) {
            return (
                `
                    <p><br data-cke-filler="true"></p>
                    <p class="approver-title">Comments</p>
                    ${
                        comments.map(
                            (comment) => (
                                `
                                    <p class="approver-title">
                                        ${comment.first_name} ${comment.last_name}
                                    </p>
                                    <p>
                                        ${comment.remarks}
                                    </p>
                                    <p style="border-bottom: 1px solid gray; text-align: right;">
                                        ${moment(comment.created_at).format('MMMM d, Y - hh:mm A')}
                                    </p>
                                `
                            )
                        ).join('')
                    }
                `
            );
        }

        return '';
    }

    tSignatories(signatories) {

        const chunkArray = (array, chunkSize) => {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        };

        const signatureApprovers = signatories.map(signature => {
            signature.approvers = chunkArray(signature.approvers, 3);
            return signature;
        });

        const approverFormat = (approver, key) => (
            `
                <div class="approver-wrapper" key="${key}">
                    <div class="approver-content">
                        <div class="approver-name">${String(approver.full_name).toUpperCase()}</div>
                        <div class="fw-bold approver-position">${String(approver.position_name).toUpperCase()}</div>
                        <div class="${['APPROVED', 'RECEIVED'].includes(approver.status) ? 'text-success' : 'text-warning'}">${approver.status}</div>
                    </div>
                </div>
            `
        );

        if (signatories.length > 0) {
            return signatureApprovers.map(
                (signature) => (
                    `
                        <p><br data-cke-filler="true"></p>
                        <p class="approver-title" key="${signature.order}">${signature.title}:</p>
                        ${
                            signature.approvers.map(
                                approvers => (
                                    `<section class="approvers" key="${signature.order}">${approvers.map(approverFormat).join('')}</section>`
                                )
                            ).join('')
                        }
                    `
                )
            ).join('');
        }

        return '';
    }
}

export default CustomCKEditor;
