
import { PDFCompiler } from "./pdf.config";
import windowOpener from "jspdf";
import PDFMake from "pdfmake";
import { Col } from "react-bootstrap";

export const LoadingRipple = () => (
	<Col
		xs={12}
		className="d-flex flex-column align-items-center justify-content-center py-5 my-5"
	>
		<div>
			<img
				className="min-w-250px"
				src="/assets/media/svg/loader.svg"
				alt="loader.svg"
			/>
		</div>
	</Col>
);

export const generateDataToPdf = async (
	approver,
	workApproval,
	commentRows,
	fileRows,
	openInNewTab = true,
	useSignatories = true
) => {
	return new Promise(resolve => {
	// eslint-disable-next-line no-unused-vars
		const win = new windowOpener();
		
		const approvers = useSignatories ? EncodeSignatures(
			workApproval.approvers,
			(value) => value,
			validSignatories
		) : {
			signatureInputs: [],
			selectedUsers: [],
		};

		PDFCompiler(
			approver,
			workApproval,
			approvers,
			commentRows,
			fileRows,
			useSignatories
		).then((compiledPDF) => {
			const pdf = PDFMake.createPdf(compiledPDF, null, {
				Lato: {
					normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
					bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
					italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
					bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`,
				},
			});
			if (openInNewTab) {
				pdf.open({}, window.open("", "pdf-print-viewer"));
			}
			resolve(pdf)
		});
	})

};

export const statusColor = (status, color = "warning") => {
	switch (status) {
		case "APPROVED":
			color = "success"; // fas fa-file-signature
			break;
		case "RECEIVED":
			color = "success"; // fas fa-file-signature
			break;
		case "REJECTED":
			color = "danger"; // fas fa-file-excel
			break;
		case "CANCELED":
			color = "danger"; // fas fa-file-excel
			break;
		case "CLOSED":
			color = "danger"; // fas fa-file-excel
			break;
		case "REQUESTED":
			color = "dark"; // fas fa-file-excel
			break;
		default: //  fas fa-file
			color = "warning";
			break;
	}
	return color;
};

export const EncodeSignatures = (
	timelines,
	callback = null,
	validSignatory = [],
	selectedUsers = []
) => {
	const signatureTitles = [];
	const signatureDatas = [];

	timelines.forEach(({ signature_title_encoded, signature_title }) => {
		if (!Object.keys(signatureTitles).includes(signature_title_encoded)) {
			signatureTitles[signature_title_encoded] = signature_title;
			signatureDatas[signature_title_encoded] = timelines.filter(
				(timeline) => timeline.signature_title_encoded === signature_title_encoded
			);
		}
	});

	return {
		signatureInputs: Object.keys(signatureTitles).map((title) => {
			const signatory = validSignatory.find(
				({ encoded }) => encoded === title
			);
			return {
				status: signatureDatas[title].status,
				order: signatory.order,
				title: signatureTitles[title],
				key: title,
				approvers: signatureDatas[title].map(
					(user, i) => {
						if (signatory && !signatory.useAllUser && !selectedUsers.includes(user.to_user_code)) {
							selectedUsers.push(user.to_user_code);
						}
						return typeof callback === "function" ? callback(user, i) : user.to_user_code;
					}
				),
				useAllUser: signatory.useAllUser,
				hasRemove: signatory.hasRemove,
			};
		}),
		selectedUsers: selectedUsers,
	};
};

export const validSignatories = [
	{
		encoded: "prepared_by",
		value: "Prepared By",
		default: true,
		useAllUser: false,
		hasRemove: false,
		order: 1,
		selectable: true,
	},
	{
		encoded: "checked_by",
		value: "Checked By",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 2,
		selectable: true,
	},
	{
		encoded: "reviewed_by",
		value: "Reviewed By",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 3,
		selectable: true,
	},
	{
		encoded: "noted_by",
		value: "Noted By",
		default: true,
		useAllUser: false,
		hasRemove: true,
		order: 4,
		selectable: true,
	},
	{
		encoded: "pending_approval",
		value: "Pending Approval",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 5,
		selectable: true,
	},
	{
		encoded: "recommending_approval",
		value: "Recommending Approval",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 6,
		selectable: true,
	},
	{
		encoded: "approved_by",
		value: "Approved By",
		default: true,
		useAllUser: false,
		hasRemove: true,
		order: 7,
		selectable: true,
	},
	{
		encoded: "received_by",
		value: "Received By",
		default: true,
		useAllUser: true,
		hasRemove: true,
		order: 8,
		selectable: true,
	},
];

export const getDefaults = (user) => {
	const storage = localStorage.getItem(user.code);
	const defaults = {
		my_request: {
			limit: 10,
			sort_by: "updated_at",
			order_by: "DESC",
			filter_status: "REQUESTED",
			search: "",
		},
		employee_request: {
			limit: 10,
			order_by: "ASC",
			filter_status: "PENDING",
			search: "",
		},
	};

	if (storage && storage !== "null") {
		const toJSON = JSON.parse(storage);
		if (toJSON) {
			defaults.my_request = toJSON.my_request;
			defaults.employee_request = toJSON.employee_request;
		}
	}

	return defaults;
};
