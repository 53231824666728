import { Plugin } from "ckeditor5";
import Ajax from "../../../../Utility/Ajax";
import { AttachmentService } from "../../../../System/WorkApproval/services/attachment.service";
import Compressor from "compressorjs";
import { findBase64EncodedImages, findImages } from "./pagination/utils/common-translations";

class UploadAdapter {
    constructor(loader, options) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.options = options;
        this.compressedImage = null;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file.then(
            file => new Promise((resolve, reject) => {

                const userFromStorage = localStorage.getItem("user");
                if (userFromStorage) {

                    try {
                        const user = JSON.parse(userFromStorage);

                        this.compressedImage = new Compressor(file, {
                            quality: file.size <= 1000000 ? 1 : 0.2,
                            convertSize: file.size <= 1000000 ? file.size : file.size / 3,
                            mimeType: file.type,
                            success: async compressedImage => {

                                // Prepare the form data.
                                const payload = new FormData();
                                payload.append('attachment_path', this.options.attachment_path);
                                payload.append('user_code', user.code);
                                payload.append('files', compressedImage);

                                AttachmentService.upload(payload, (_, loaded, total) => {
                                    this.loader.uploadTotal = total;
                                    this.loader.uploaded = loaded;
                                }).then(
                                    image => {
                                        const imageUrl = `${Ajax.env.API_URI}/${image.url}`;

                                        this.options.adapterResponse({
                                            attachment_path: image.attachment_path,
                                            directory: image.directory,
                                            name: image.name,
                                            url: imageUrl
                                        });

                                        resolve({
                                            default: imageUrl
                                        });
                                    }
                                ).catch(error => {
                                    reject(error);
                                });
                            },
                            error: () => {
                                reject('Unable to compress file');
                            }
                        });

                    } catch (error) {
                        reject('Unable to compress file');
                    }
                } else {
                    reject('User not available');
                }
            })
        );
    }
}


class ImageUploader extends Plugin {

    // get uploadUrl() {
    //     return `${Ajax.env.API_URI}/work-approval/attachments/images`
    // }

    static get pluginName() {
        return 'ImageUploader';
    }

    init() {

        this.set({
            files: [],
            attachment_path: this.getAttachmentPath()
        });

        const fileRepository = this.editor.plugins.get('FileRepository');

        if (fileRepository) {

            fileRepository.createUploadAdapter = loader => new UploadAdapter(loader, {
                attachment_path: this.attachment_path,
                adapterResponse: file => {

                    this.set({
                        files: this.files.concat(file),
                        attachment_path: file.attachment_path
                    });
                }
            });

            this.editor.once('ready', () => {

                this._processUploadedImages();
            })
        }
    }

    getAttachmentPath() {
        const attachmentPath = this.editor.config.get('attachment_path');

        if (attachmentPath) {
            return attachmentPath;
        }
        
        return '';
    }

    getInlineImages(images) {
        const getInlineImages = this.editor.config.get('getInlineImages');

        if (getInlineImages) {
            if (typeof getInlineImages === 'function') {
                const getImageSources = images.map(
                    image => image.getAttribute('src')
                );

                return getInlineImages(getImageSources, this.attachment_path);
            }
        }

        return images;
    }

    _processUploadedImages() {

        const paragraphHeight = 20;

        const root = this.editor.model.document.getRoot();

        this.editor.model.document.on('change:data', () => {
            
            // const removedImage = changes.source.history.lastOperation.batch.operations.filter(operation => {
            //     if (operation.targetPosition) {
            //         return operation.type === 'remove' && imageTypes.includes(operation.targetPosition.nodeAfter.name) && operation.targetPosition.nodeAfter.hasAttribute('src');
            //     }
            //     return operation.type === 'remove'
            // }).map(operation => operation.targetPosition.nodeAfter.getAttribute('src'));
            // console.log(removedImage)

            this.editor.model.change(
                writer => {

                    const rootView = this.editor.editing.mapper.toViewElement(root);
                    const rootDom = this.editor.editing.view.domConverter.mapViewToDom(rootView);

                    let maxWidth = rootDom.getBoundingClientRect().width - (rootDom.getBoundingClientRect().width / 3);
                    maxWidth = maxWidth - (maxWidth % paragraphHeight);

                    const images = findImages(root);

                    // remove base64EncodedImage
                    if (!this.editor.isReadOnly) {
                        findBase64EncodedImages(images).forEach(
                            image => writer.remove(writer.createRangeOn(image))
                        );
                    }

                    images.forEach(
                        image => {

                            const hasAttributes = image.hasAttribute('height') && image.hasAttribute('width');

                            if (hasAttributes) {

                                const height = parseFloat(image.getAttribute('height'));
                                const width = parseFloat(image.getAttribute('width'));

                                const validHeight = Math.min(height, maxWidth);

                                // recalculate the image ratio to divisible by paragraph height
                                const calcHeight = validHeight - (validHeight % paragraphHeight);
                                const calcWidth = (width / height) * calcHeight;

                                const originalResizedWidth = image.hasAttribute('resizedWidth') ? (
                                    parseFloat(String(image.getAttribute('resizedWidth')).replace('px', ''))
                                ) : calcWidth;

                                const originalResizeHeight = (calcHeight / calcWidth) * originalResizedWidth;

                                 // change original size to max width when image width reached the max width
                                const resizedWidth = Math.min(originalResizedWidth, maxWidth);
                                const resizedHeight = (originalResizeHeight / originalResizedWidth) * resizedWidth;

                                // if there is resizedWidth attribute, recalculate the image container ratio to divisible by paragraph height
                                const containerHeight = resizedHeight - (resizedHeight % paragraphHeight);
                                const containerWidth = (resizedWidth / resizedHeight) * containerHeight;
                                const resizedWidthInPx = `${containerWidth}px`;
                                
                                // set the new image dimensions
                                writer.setAttribute('height', containerHeight, image);
                                writer.setAttribute('width', containerWidth, image);
                                
                                writer.setAttribute('resizedWidth', resizedWidthInPx, image);
                            }
                        }
                    );

                    this.getInlineImages(images);
                }
            );
        });
    }
}


export {
    ImageUploader
}