import React, { createRef, forwardRef } from "react";
import { Button, Container, Form, InputGroup, Modal, Row, Stack } from "react-bootstrap";
import QUERY from 'lodash';
import * as validate from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isInvalid } from "../../../../Utility/Plugins";
import { validSignatories } from "../other/helpers";
import { produce } from 'immer';

import { MyRequestService } from "../../services/my-request.service";
import { UserAccountService } from "../../../Admin/services/users/account.service";

import Alert from "../../../../Utility/sweet-alert";
import MultiSelect from "../../../../Utility/MultiSelect";
import CustomCKEditor from '../../../../Component/Custom/ckeditor/CustomCKEditor';
import { imageConfig, plugins, tableConfig, toolbars } from "../../../../Component/Custom/ckeditor/plugins";


const formValidation = validate.object({
    id: validate.string().notRequired(),
    mail_to: validate.string().min(3).required().label("To"),
    mail_from: validate.string().min(3).required().label("From"),
    subject: validate.string().min(3).required().label("Subject"),
    letter: validate.string().min(10).required().label("Letter"),
    letter_characters: validate.number().min(10).max(5000).required().label("Letter"),
    mail_code: validate.string().notRequired().label("Mail Code"),
    create_from_existing: validate.number().default(0).notRequired(),
});

const WithUseCases = forwardRef(({ user = null, reloadRequests = () => null }, ref) => {
    
    const WAForm = useForm({
        resolver: yupResolver(formValidation)
    });

    return (
        <RequestFormComponent
            ref={ref}
            user={user}
            form={WAForm}
            reloadRequests={reloadRequests}
        />
    );
})

class RequestFormComponent extends React.Component {

    refWALetterForm = createRef(null);

    constructor() {
        super();
        this.swal = new Alert();

        this.state = {
            submitting: false,
            editing: false,
            modalOpen: false,
            options: {
                users: []
            },
            selected: {
                approvers: [],
                signatories: []
            },
            attachments: [],
            attachment_path: '',
            signatory: ''
        }
    }

    componentDidMount() {
        this.props.form.register('create_from_existing');
        this.props.form.register('mail_code');
        this.props.form.register('letter_characters');
    }

    signatureFormat(inputSignatory, i) {
        return (
            <Form.Group className="col-12 mb-3" key={i}>
                <Form.Label className="col-12">
                    <strong>{inputSignatory.title}</strong>
                </Form.Label>
                <InputGroup>
                    <MultiSelect
                        control={this.props.form.control}
                        errors={this.props.form.formState.errors}
                        values={inputSignatory.approvers}
                        name={inputSignatory.key}
                        options={
                            inputSignatory.useAllUser ? (
                                this.state.options.users.filter(
                                    ({ value }) => !this.state.selected.signatories.find(
                                        signatory => signatory.key === 'prepared_by'
                                    ).approvers.includes(value)
                                )
                            ) : (
                                this.state.options.users.filter(
                                    ({ value }) => !this.state.selected.approvers.filter(
                                        (selectApprover) => !inputSignatory.approvers.includes(selectApprover)
                                    ).includes(value)
                                )
                            )
                        }
                        onChanged={(onChangeOptions, changed) => {
                            this.setState(
                                prev => produce(prev, ({ selected }) => {

                                    const signatory = QUERY.find(selected.signatories, {
                                        key: inputSignatory.key
                                    });

                                    if (signatory) {
                                        // validate for active users only - when edited as new
                                        signatory.approvers = onChangeOptions.filter(
                                            ({ value }) => this.state.options.users.find(
                                                user => String(user.value) === value
                                            )
                                        )?.map(({ value }) => value).sort((a, b) => a - b);
                                    }

                                    if (['prepared_by'].includes(signatory.key)) {
                                        const checkDefaults = signatory.approvers.find(
                                            approver => String(approver) === String(this.props.user.code)
                                        );
                                        if (!checkDefaults) {
                                            signatory.approvers.push(this.props.user.code);
                                        }
                                    }

                                    if (!inputSignatory.useAllUser) {
                                        selected.approvers = selected.signatories.reduce(
                                            (selectedApprovers, signature) => selectedApprovers.concat(signature.approvers), []
                                        );
                                    }

                                })
                            );

                            const checkDefaults = onChangeOptions.find(
                                ({ value }) => String(value) === String(this.props.user.code)
                            );

                            if (checkDefaults) {
                                return changed(onChangeOptions?.map(({ value }) => value));
                            }

                            return changed(
                                [this.props.user.code].concat(onChangeOptions?.map(({ value }) => value))
                            );
                        }}
                    />
                    {
                        inputSignatory.hasRemove && (
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={() => this.onRemoveSignatory(inputSignatory)}
                            >
                                Remove
                            </Button>
                        )
                    }
                </InputGroup>
                <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                    {this.props.form.formState.errors[inputSignatory.key]?.message}
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    render() {
        return (
            <Modal
                show={this.state.modalOpen}
                size="xl"
                fullscreen="sm-down"
                className="bg-gray-100"
                enforceFocus={false}
                backdrop={false}
                keyboard={false}
            >
                <Form
                    noValidate
                    className="modal-content"
                    onSubmit={
                        this.props.form.handleSubmit(
                            validated => this.onValidateFormRequest(validated)
                        )
                    }
                    encType="multipart/form-data"
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.editing ? 'EDIT' : 'CREATE'} REQUEST
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="row show-scrollbar" style={{
                        padding: '0px 1.75rem'
                    }}>

                        <Form.Group className="col-12 col-md-6 my-5">
                            <Form.Label>
                                <strong>Requested To</strong>
                            </Form.Label>
                            <Form.Control
                                {...this.props.form.register("mail_to")}
                                required
                                type="text"
                                autoComplete="on"
                                placeholder="enter requested to name"
                                className="border border-gray-400 rounded-1"
                                isInvalid={isInvalid(this.props.form.formState.errors, "mail_to")}
                                readOnly={this.state.submitting}
                            />
                            <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                {this.props.form.formState.errors.mail_to?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="col-12 col-md-6 my-5">
                            <Form.Label>
                                <strong>Requested By</strong>
                            </Form.Label>
                            <Form.Control
                                {...this.props.form.register("mail_from")}
                                required
                                type="text"
                                autoComplete="on"
                                placeholder="enter requested by name"
                                className="border border-gray-400 rounded-1"
                                isInvalid={isInvalid(this.props.form.formState.errors, "mail_from")}
                                readOnly={this.state.submitting}
                            />
                            <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                {this.props.form.formState.errors.mail_from?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="col-12 col-md-12 mb-5">
                            <Form.Label>
                                <strong>Subject</strong>
                            </Form.Label>
                            <Form.Control
                                {...this.props.form.register("subject")}
                                required
                                type="text"
                                autoComplete="off"
                                placeholder="enter subject here . . ."
                                className="border border-gray-400 rounded-1"
                                isInvalid={isInvalid(this.props.form.formState.errors, "subject")}
                                readOnly={this.state.submitting}
                            />
                            <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                {this.props.form.formState.errors.subject?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label>
                                <strong>Letter</strong>
                            </Form.Label>

                            <CustomCKEditor
                                ref={this.refWALetterForm}
                                enabled={true}
                                config={
                                    {
                                        menuBar: {
                                            isVisible: true
                                        },
                                        plugins: plugins,
                                        toolbar: toolbars,
                                        table: tableConfig,
                                        image: imageConfig,
                                        placeholder: 'Compose here . . .',
                                        label: 'Work Approval Letter',
                                        pagination: {
                                            // A4
                                            pageWidth: '794px',
                                            pageHeight: '1092px',
                                            pageMargins: {
                                                top: '20mm',
                                                bottom: '20mm',
                                                right: '12mm',
                                                left: '12mm'
                                            }
                                        },
                                        wordCount: {
                                            onUpdate: ({ characters }) => {
                                                this.props.form.setValue('letter_characters', characters);
                                            }
                                        },
                                        getInlineImages: (attachments, attachmentPath) => {
                                            this.setState(
                                                prev => produce(prev, current => {
                                                    current.attachments = attachments
                                                    current.attachment_path = attachmentPath
                                                })
                                            );
                                        },
                                        attachment_path: this.state.attachment_path,
                                        workApproval: () => this.getDocumentPrinting()
                                    }
                                }
                                value={this.props.form.watch('letter')}
                                onChange={
                                    letter => {
                                        this.props.form.setValue('letter', letter)
                                    }
                                }
                            />
                            <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                {this.props.form.formState.errors.letter?.message || this.props.form.formState.errors.letter_characters?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Container
                            className="d-flex flex-wrap justify-content-center px-0"
                            fluid
                        >
                            <Row className="col-12">
                                <div className="d-flex justify-content-center">
                                    <Form.Group className="col-12 mb-3">
                                        <Form.Label>
                                            <strong>Add Signatory</strong>
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Select
                                                className="border-gray-300"
                                                value={this.state.signatory}
                                                readOnly={this.state.submitting}
                                                onChange={
                                                    (select) => {
                                                        this.setState(
                                                            (prev) => produce(
                                                                prev, state => {
                                                                    state.signatory = select.target.value
                                                                }
                                                            )
                                                        )
                                                    }
                                                }
                                            >
                                                <option value="">
                                                    - - select signatory here - -
                                                </option>
                                                {
                                                    validSignatories.filter(vs => vs.selectable).filter(
                                                        vs => !this.state.selected.signatories.map(
                                                            signatory => signatory.key
                                                        ).includes(vs.encoded)
                                                    ).sort((a, b) => a.order - b.order).map((signatory, i) => (
                                                        <option value={signatory.encoded} key={i}>
                                                            {signatory.value}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                            <Button
                                                size="sm"
                                                variant="warning"
                                                disabled={this.state.submitting}
                                                onClick={() => this.onCreateSignatory()}
                                            >
                                                Create
                                            </Button>
                                        </InputGroup>
                                        <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                            <Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
                                                {this.props.form.formState.errors.signatures?.message}
                                            </Form.Control.Feedback>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                {
                                    this.state.selected.signatories.map(
                                        (signatory, i) => this.signatureFormat(signatory, i)
                                    )
                                }
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className="col-12 d-flex justify-content-between">
                        <Stack direction="horizontal" gap={3}>
                            <Button
                                variant="primary"
                                size="sm"
                                type="submit"
                                disabled={this.state.submitting}
                            >
                                {this.state.editing ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                variant="outline-warning"
                                size="sm"
                                disabled={this.state.submitting}
                                onClick={() => {
                                    this.refWALetterForm.current.onPrint()
                                }}
                            >
                                Preview Letter
                            </Button>
                        </Stack>
                        <Button
                            size="sm"
                            variant="outline-dark"
                            disabled={this.state.submitting}
                            onClick={
                                () => {
                                    this.setState({
                                        modalOpen: false
                                    });
                                }
                            }
                        >
                            Close Window
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    getUsers() {
        return new Promise(resolve => {
            this.setState({
                submitting: true
            }, () => {
                UserAccountService.lists({ active: 1 }).then(users => {
                    this.setState(
                        prev => produce(prev, state => {
                            state.options.users = users.map(
                                (row) => ({
                                    user: row,
                                    label: `${row.last_name}, ${row.first_name} ${row.middle_name}`,
                                    value: row.user_code
                                })
                            )
                            state.submitting = false
                        })
                    );
                }).catch(() => {
                    this.setState(
                        prev => produce(prev, state => {
                            state.options.users = []
                            state.submitting = false
                        })
                    );
                }).finally(resolve);
            });
        });
    }

    getDocumentPrinting() {
        const approvers = []

        this.state.selected.signatories.forEach(
            signatory => {
                signatory.approvers.forEach(
                    approver => {
                        const userExists = this.state.options.users.find(
                            user => String(user.value) === String(approver)
                        );

                        if (userExists?.user) {
                            const { user } = userExists;

                            approvers.push({
                                first_name: user.first_name,
                                from_user_code: this.props.user.code,
                                full_name: user.full_name,
                                id: user.id,
                                last_name: user.last_name,
                                middle_name: user.middle_name,
                                position_name: user.position_name,
                                signature_order: signatory.order,
                                signature_title: signatory.title,
                                signature_title_encoded: signatory.key,
                                status: "PENDING",
                                status_at: null,
                                suffix_name: user.suffix_name,
                                to_user_code: approver,
                            });
                        }
                    }
                );
            }
        );

        const { mail_to, mail_from, subject, letter } = this.props.form.getValues();

        return {
            mail_to,
            mail_from,
            subject,
            letter,
            approvers,
            comments: []
        };
    }

    onCreateSignatory() {
        this.setState(
            prev => produce(prev, (state) => {
                const exists = state.selected.signatories.find(
                    signatory => String(signatory.key) === String(state.signatory)
                );

                if (!exists) {
                    const signature = validSignatories.find(
                        signatory => String(signatory.encoded) === String(state.signatory)
                    );

                    if (signature) {
                        state.selected.signatories = [{
                            title: signature.value,
                            key: signature.encoded,
                            approvers: [],
                            useAllUser: signature.useAllUser,
                            order: signature.order,
                            hasRemove: signature.hasRemove
                        }].concat(state.selected.signatories).sort(
                            (a, b) => a.order - b.order
                        );

                        state.signatory = '';
                    }
                }
            })
        );
    }

    onRemoveSignatory(inputSignatory) {

        this.props.form.unregister(inputSignatory.key);

        this.setState(
            prev => produce(prev, ({ selected }) => {
                selected.signatories = selected.signatories.filter(
                    ({ key }) => key !== inputSignatory.key
                ).sort((first, second) => first.order - second.order);

                selected.users = selected.signatories.reduce(
                    (selectedApprovers, signature) => selectedApprovers.concat(signature.approvers), []
                ).sort((first, second) => first.order - second.order);
            })
        );

    }

    onCreateRequest() {
        this.getUsers().then(() => {

            this.props.form.setValue('id', '');
            this.props.form.setValue('mail_to', '');
            this.props.form.setValue('mail_from', '');
            this.props.form.setValue('subject', '');
            this.props.form.setValue('letter', '');
            this.props.form.setValue('mail_code', '');
            this.props.form.setValue('create_from_existing', 0);

            this.props.form.clearErrors();

            this.setState(
                prev => produce(
                    prev, ({ selected }) => {
                        selected.signatories = validSignatories.filter(
                            signatory => signatory.default && signatory.selectable
                        ).map(
                            signatory => ({
                                title: signatory.value,
                                key: signatory.encoded,
                                approvers: ['prepared_by'].includes(signatory.encoded) ? [this.props.user.code] : [],
                                useAllUser: signatory.useAllUser,
                                order: signatory.order,
                                hasRemove: signatory.hasRemove,
                                default: signatory.default,
                                selected: signatory.selected
                            })
                        ).sort((a, b) => a.order - b.order);
                    }
                ),
                () => {
                    this.setState({
                        editing: false,
                        modalOpen: true,
                        attachment_path: '',
                        attachments: []
                    });
                }
            );

        });
    }

    onEditRequest(workApproval, asNew = 0) {
        const attachmentPath = ['', ' ', null, 'null'].includes(workApproval.attachment_path) ? workApproval.mail_code : workApproval.attachment_path;

        this.setState({
            attachment_path: attachmentPath,
            attachments: workApproval.attachments || []
        }, () => {

            this.getUsers().then(() => {
                this.props.form.setValue('id', workApproval.id);
                this.props.form.setValue('mail_to', workApproval.mail_to);
                this.props.form.setValue('mail_from', workApproval.mail_from);
                this.props.form.setValue('subject', workApproval.subject);
                this.props.form.setValue('letter', workApproval.letter);
                this.props.form.setValue('mail_code', workApproval.mail_code);
                this.props.form.setValue('create_from_existing', asNew);

                this.setState(
                    prev => produce(prev, ({ selected, options }) => {

                        try {
                            const parsedSignatures = JSON.parse(workApproval.signatures);

                            if (parsedSignatures.length > 0) {
                                selected.signatories = parsedSignatures.map(
                                    pSignature => {
                                        const validSignatory = validSignatories.find(
                                            vs => String(vs.encoded) === String(pSignature.key)
                                        );

                                        if (!validSignatory) {
                                            throw new Error('Invalid Signatory');
                                        }

                                        return {
                                            title: pSignature.title,
                                            key: pSignature.key,
                                            approvers: pSignature.approvers.filter(
                                                userCode => options.users.find(
                                                    ({ value }) => String(value) === String(userCode)
                                                )
                                            ),
                                            useAllUser: validSignatory.useAllUser,
                                            order: validSignatory.order,
                                            hasRemove: validSignatory.hasRemove,
                                            default: validSignatory.default,
                                            selected: validSignatory.selected
                                        };
                                    }
                                );
                            } else {
                                throw new Error('No Valid Signatories');
                            }
                        } catch (error) {
                            selected.signatories = Array.from(selected.signatories).concat(
                                validSignatories.filter(vs => vs.default && vs.selectable).map(
                                    signatory => ({
                                        title: signatory.value,
                                        key: signatory.encoded,
                                        approvers: ['prepared_by'].includes(signatory.encoded) ? [this.props.user.code] : [],
                                        useAllUser: signatory.useAllUser,
                                        order: signatory.order,
                                        hasRemove: signatory.hasRemove,
                                        default: signatory.default,
                                        selected: signatory.selected
                                    })
                                )
                            ).sort((a, b) => a.order - b.order);
                        }
                    }), () => {

                        this.props.form.clearErrors();

                        this.setState({
                            editing: asNew === 0,
                            modalOpen: true
                        });
                    }
                );
            });
        });

    }

    onValidateFormRequest(form) {
        const hasEmptyFields = this.state.selected.signatories.filter((signatory) => {
            if (signatory.approvers.length <= 0) {
                this.props.form.setError(signatory.key, {
                    message: `${signatory.title} field is required.`
                });
            }
            return signatory.approvers.length <= 0;
        })

        if (hasEmptyFields.length > 0) {
            return this.swal.invalid();
        }

        const noApprovers = this.state.selected.signatories.filter(
            ({ order }) => Number(order) < 8 && Number(order) > 1
        )

        if (noApprovers.length <= 0) {
            const validSelections = validSignatories.filter(
                ({ order, selectable }) => Number(order) < 8 && Number(order) > 1 && selectable
            ).map(({ value }) => value).join(', ')

            return this.swal.invalid(`Please add atleast one approver from ( ${validSelections})`)
        }

        this.swal.confirm({
            html: this.state.editing ? 'Update Work Approval Request?' : 'Save Work Approval Request?',
            preConfirm: () => {
                if (this.state.editing) {
                    return this.onPutWorkApproval(form);
                }

                return this.onPostWorkApproval(form);
            }
        })
    }

    onPostWorkApproval(form) {
        return new Promise(resolve => {
            MyRequestService.post({
                ...form,
                department_code: this.props.user.department.code,
                created_by: this.props.user.code,
                signatures: this.state.selected.signatories.map(
                    signatory => ({
                        title: signatory.title,
                        key: signatory.key,
                        approvers: signatory.approvers,
                        order: signatory.order
                    })
                ),
                attachment_path: this.state.attachment_path,
                attachments: this.state.attachments
            }).then(({ message }) => {
                this.swal.success(message).then(() => {
                    this.setState({
                        modalOpen: false
                    }, () => {
                        this.props.reloadRequests()
                    });
                });
            }).catch(({ required }) => {
                if (required) {
                    Object.keys(required).forEach(
                        field => this.props.form.setError(
                            field, {
                                message: required[field]
                            }
                        )
                    )
                }
            }).finally(resolve);
        })
    }

    onPutWorkApproval(form) {
        return new Promise(resolve => {
            MyRequestService.put({
                ...form,
                department_code: this.props.user.department.code,
                created_by: this.props.user.code,
                signatures: this.state.selected.signatories.map(
                    signatory => ({
                        title: signatory.title,
                        key: signatory.key,
                        approvers: signatory.approvers,
                        order: signatory.order
                    })
                ),
                attachment_path: this.state.attachment_path,
                attachments: this.state.attachments
            }).then(({ message }) => {
                this.swal.success(message).then(() => {
                    this.setState({
                        modalOpen: false
                    }, () => {
                        this.props.reloadRequests()
                    });
                });
            }).catch(({ required }) => {
                if (required) {
                    Object.keys(required).forEach(
                        field => this.props.form.setError(
                            field, {
                                message: required[field]
                            }
                        )
                    );
                }
            }).finally(resolve);
        })
    }

    onRenotifyRequest(form) {
        const currentApprovers = validSignatories.find(
            ({ order }) => Number(order) === Number(form.current_signature_level)
        );

        this.swal.confirm({
            html: `This will notify all approvers of ${currentApprovers.value}?`,
            confirmButtonText: 'Renotity',
            preConfirm: async () => {
                return await MyRequestService.renotify({
                    token: form.token
                }).then(({ message }) => {
                    this.props.reloadRequests();
                    this.swal.success(message);
                });
            }
        });
    }

    onCancelRequest(form) {
        this.swal.confirm({
            html: `Mark your request as Canceled?`,
            confirmButtonText: 'Mark as Canceled',
            customClass: {
                confirmButton: 'btn btn-sm btn-danger my-1'
            },
            preConfirm: async () => {
                return await MyRequestService.cancel({
                    id: form.id
                }).then(({ message }) => {
                    this.props.reloadRequests();
                    this.swal.success(message);
                });
            }
        })
    }
}


export default WithUseCases