import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Ajax from "../../../../Utility/Ajax";
import { LoadingSideList, NoPost } from "../utils/utils";

export default function TopPosts({
	channel = null,
	user = null,
	forum,
	onSelect,
}) {
	const navigate = useNavigate();

	const [topPosts, setTopPosts] = useState({
		processing: true,
		rows: [],
	});

	const onLoadTopPosts = (channel_code, user_code) => {
		Ajax.post(
			"/forum-v2/post/top-five/",
			{
				channel_code: channel_code,
				user_code: user_code,
			},
			() => {
				setTopPosts((old) => ({
					processing: true,
					rows: old.rows,
				}));
			},
			(result) => {
				switch (result.response) {
					case "resource-found": {
						setTopPosts({
							processing: false,
							rows: result.rows,
						});
						break;
					}
					default: {
						setTopPosts((old) => ({
							processing: false,
							rows: old.rows,
						}));
						break;
					}
				}
			},
			() => {
				setTopPosts((old) => ({
					processing: false,
					rows: old.rows,
				}));
			}
		);
	};

	useEffect(() => {
		if (channel && user) {
			onLoadTopPosts(channel, user);
		}
	}, [channel, user]);
	return (
		<div className="mb-5">
			<Card className="border border-1 border-gray-300 rounded-0">
				<Card.Header className="px-3 border-1 border-bottom border-gray-300">
					<Card.Title>
						<i className="fs-1 fas fa-fire me-2 text-danger"></i> Hot Posts
					</Card.Title>
				</Card.Header>
				<Card.Body className="p-5 py-0 pt-3">
					<Row>
						{topPosts.processing
							? LoadingSideList()
							: topPosts.rows.length > 0
							? topPosts.rows.map((post, i) => (
									<Col
										xs={12}
										className="d-flex justify-content-between py-3"
										key={post.post_code}
									>
										<div className="d-flex align-self-center">
											<div className="me-4 fs-5">{i + 1}</div>
											<div className="d-flex flex-column">
												<div className="ms-2 fw-bolder fs-5 mb-2">
													{post.post_title}
												</div>
												<div className="ms-0">
													<div className="d-flex align-self-end">
														<div className="fs-8 p-0 px-3">
															{post.total_votes > 1
																? `${post.total_votes} Votes`
																: `${post.total_votes} Vote`}
														</div>
														<div className="fs-8 p-0 px-3">
															{post.total_comments > 1
																? `${post.total_comments} Comments`
																: `${post.total_comments} Comment`}
														</div>
														<div className="fs-8 p-0 px-3">
															{post.total_views > 1
																? `${post.total_views} Views`
																: `${post.total_views} View`}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="align-self-center">
											<Button
												size="sm"
												variant="primary"
												className="px-2 py-1"
												onClick={() =>
													forum && typeof onSelect === "function"
														? onSelect(forum, () => {
																window.location.href = `/system/forum-v2/${post.channel_code}/${post.post_code}`;
														  })
														: navigate(
																`/system/forum-v2/${post.channel_code}/${post.post_code}`
														  )
												}
											>
												View
											</Button>
										</div>
									</Col>
							  ))
							: NoPost()}
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
}
