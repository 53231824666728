import { View, Plugin, Rect, global, logWarning } from 'ckeditor5';
import { debounce } from 'lodash';
import { allImagesAreReady, createDomRangeOn, findOffsetTopForTextOffset, findPageStarterInfos, getTopMostParentBlockTouchingPosition, isHTMLElement, pageElementToViewElement } from './utils/common-translations';

const COLUMN_GAP = 774, EDITING_RENDER_TIMEOUT = 0xc8;

class PageBreakInfo {
    constructor({ type, modelRange, domNode, offset }) {
        this.type = type;
        this.modelRange = modelRange;
        this.domNode = domNode;
        this.offsetValue = offset;
    }

    get offset() {
        // Check if the DOM node is in the document.
        if (!this.domNode.ownerDocument.body.contains(this.domNode)) {
            return 0;
        }

        // Create a rectangle for the DOM node, or a range if it's not an HTMLElement.
        const rect = new Rect(isHTMLElement(this.domNode) ? this.domNode : createDomRangeOn(this.domNode));

        // Return offset based on whether it is a 'manual' page break.
        return this.type === 'manual'
            ? rect.top + rect.height / 2
            : rect.top + this.offsetValue;
    }
}


export class PaginationLooker extends Plugin {
    constructor() {
        super(...arguments);
        this.pagesContainer = null;
    }

    static get pluginName() {
        return 'PaginationLooker';
    }

    init() {
        this.set({ pageBreaks: [] });

        if (!this.editor.config.get('pagination')) {
            logWarning('pagination-config-not-found', this.editor.config);
            return;
        }

        this.recalculatePageBreaksDebounced = debounce(
            () => this.recalculatePageBreaks(),
            EDITING_RENDER_TIMEOUT
        );

        const paginationEditing = this.editor.plugins.get('PaginationEditing');
        this.bind('isEnabled').to(paginationEditing);
        
        this.editor.ui.once('ready', () => this.onUiReady());

        this.on('change:isEnabled', (_, __, isEnabled) => {
            if (isEnabled) {
                this.recalculatePageBreaksDebounced();
            }
        });
    }

    destroy() {
        super.destroy();

        if (this.pagesContainer) {
            this.recalculatePageBreaksDebounced.cancel();
            this.pagesContainer.destroy();
            this.pagesContainer.element.remove();
        }
    }

    onUiReady() {
        const editor = this.editor;
        const view = editor.editing.view;
        const domRoot = this.getAttachedDomRoot();

        if (!domRoot || domRoot.childElementCount === 0) {
            editor.ui.once('update', () => this.onUiReady());
            return;
        }

        const { pageWidth, pageHeight } = editor.config.get('pagination');
        const { top, left, bottom, right } = this.configuredPaddings;

        this.pagesContainer = new View(editor.locale);
        this.pagesContainer.setTemplate({
            tag: 'div',
            attributes: {
                contenteditable: true,
                style: {
                    columnWidth: pageWidth,
                    width: pageWidth,
                    height: `calc(${pageHeight} - ${top} - ${bottom})`,
                    minWidth: 'auto',
                    minHeight: 'auto',
                    padding: `0 ${right} 0 ${left}`,
                    columnGap: `${COLUMN_GAP}px`,
                    border: 0,
                    boxSizing: 'border-box',
                    columnCount: 'auto',
                    columnFill: 'auto',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '-99999px',
                    left: '-99999px'
                },
                tabindex: '-1'
            }
        });

        this.pagesContainer.render();
        domRoot.parentNode.insertBefore(this.pagesContainer.element, domRoot.nextSibling);

        this.listenTo(view, 'render', () => this.recalculatePageBreaksDebounced(), { priority: 'low' });
        this.recalculatePageBreaksDebounced();
    }

    getAttachedDomRoot() {
        for (const domRoot of this.editor.editing.view.domRoots.values()) {
            if (domRoot.ownerDocument.body.contains(domRoot)) {
                return domRoot;
            }
        }
        return null;
    }

    async recalculatePageBreaks() {
        if (!this.isEnabled) return Promise.resolve();

        const view = this.editor.editing.view;

        const domRoot = this.getAttachedDomRoot();

        if (!domRoot) return Promise.resolve();

        const container = this.pagesContainer;
        container.class = ['ck-reset', 'ck-pagination-view', ...domRoot.classList].join(' ');

        while (container.element.firstChild) {
            container.element.firstChild.remove();
        }

        const domRoots = Array.from(view.domRoots).sort(([, a], [, b]) =>
            a.compareDocumentPosition(b) === Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1
        );

        for (const [rootName, root] of domRoots) {
            const clonedRoot = root.cloneNode(true);
            if ('attributeStyleMap' in clonedRoot) {
                clonedRoot.attributeStyleMap.clear();
            }
            clonedRoot.className = '';
            clonedRoot.removeAttribute('id');
            clonedRoot.dataset.rootName = rootName;
            container.element.append(clonedRoot);
        }

        await allImagesAreReady(Array.from(container.element.querySelectorAll('img')));

        return this.updatePageBreaksData();
    }

    updatePageBreaksData() {
        const containerElement = this.pagesContainer.element;
        if (!containerElement.ownerDocument.body.contains(containerElement)) return;

        const rect = new Rect(containerElement).excludeScrollbarsAndBorders();
        const styles = global.window.getComputedStyle(containerElement);
        const paddingWidth = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
        const columnWidth = Math.floor(rect.width + COLUMN_GAP - paddingWidth);

        const pageBreaksData = findPageStarterInfos(
            containerElement,
            Math.floor(rect.left - containerElement.scrollLeft),
            columnWidth
        ).map(info => this.mapPageStarterInfoToPageBreakInfo(info));

        const validPageBreaks = pageBreaksData.every(data => data)
        if (validPageBreaks) {
            if (pageBreaksData.length !== this.pageBreaks.length) {
                this.pageBreaks = pageBreaksData;
            }
        }
    }

    mapPageStarterInfoToPageBreakInfo(pageStarterInfo) {
        const view = this.editor.editing.view;
        const mapper = this.editor.editing.mapper;
        const domConverter = view.domConverter;
        
        // Process the path to determine the correct DOM element.
        const rootIndex = pageStarterInfo.path.shift();
        const rootName = this.pagesContainer.element.childNodes[rootIndex]?.dataset?.rootName;
        const domRoot = view.getDomRoot(rootName);

        
        if (!domRoot) return null;

        const viewElement = pageElementToViewElement(domRoot, pageStarterInfo);
        if (!viewElement) return null;

        const viewPosition = domConverter.domPositionToView(viewElement, pageStarterInfo.textOffset || 0);
        
        if (!viewPosition) return null;

        // Determine the mapping function based on the type of page starter info.
        switch (pageStarterInfo.type) {
            case 'manual':
                return this.mapManualPageStarterInfoToPageBreakInfo(viewPosition, viewElement);
            case 'text':
                if (mapper.toModelPosition(viewPosition).offset <= 0) {
                    return this.mapTextPageStarterInfoToPageBreakInfo(viewPosition, viewElement, pageStarterInfo.textOffset);
                }
                break;
            default: {
                return this.mapElementPageStarterInfoToPageBreakInfo(viewPosition, viewElement, pageStarterInfo.offset);
            }
        }

        return null;
    }

    mapManualPageStarterInfoToPageBreakInfo(viewPosition, domNode) {
        const model = this.editor.model;
        const modelElement = this.editor.editing.mapper.toModelElement(viewPosition?.parent);
        const modelRange = model.createRangeOn(modelElement);

        return new PageBreakInfo({
            type: 'manual',
            modelRange,
            domNode,
            offset: 0
        });
    }

    mapTextPageStarterInfoToPageBreakInfo(viewPosition, domNode, textOffset) {
        const model = this.editor.model;
        const mapper = this.editor.editing.mapper;
        const domConverter = this.editor.editing.view.domConverter;

        if (textOffset >= domNode.length) return null;

        const modelPosition = mapper.toModelPosition(viewPosition);
        const modelRange = model.createRange(modelPosition);
        const ancestor = mapper.findMappedViewAncestor(viewPosition);
        const ancestorDomNode = domConverter.mapViewToDom(ancestor);
        const offset = findOffsetTopForTextOffset(domNode, textOffset, ancestorDomNode);

        return new PageBreakInfo({
            type: 'text',
            modelRange,
            domNode,
            offset
        });
    }

    mapElementPageStarterInfoToPageBreakInfo(viewPosition, domNode, offset) {
        const model = this.editor.model;
        const editing = this.editor.editing;
        const mapper = editing.mapper;
        const domConverter = editing.view.domConverter;

        const ancestor = mapper.findMappedViewAncestor(viewPosition);
        const modelElement = mapper.toModelElement(ancestor);
        const topMostBlock = getTopMostParentBlockTouchingPosition(model.createPositionBefore(modelElement)) || modelElement;

        let viewElement = mapper.toViewElement(topMostBlock);
        let modelRange = model.createRangeOn(topMostBlock);

        const prevNode = model.createPositionBefore(topMostBlock).nodeBefore;
        
        if (prevNode?.is('element', 'imageBlock')) {
            const prevViewElement = mapper.toViewElement(prevNode);
            const prevDomNode = domConverter.mapViewToDom(prevViewElement);
            const floatStyle = global.window.getComputedStyle(prevDomNode).float;

            if (['left', 'right'].includes(floatStyle)) {
                modelRange = model.createRangeOn(prevNode);
            }
        }

        // Ascend to the root view element if required.
        while (viewElement.parent !== viewElement.root && viewElement.index === 0) {
            viewElement = viewElement.parent;
        }

        const domRect = new Rect(isHTMLElement(domNode) ? domNode : createDomRangeOn(domNode));
        const heightOffset = offset ? domRect.height - offset : 0;
        const mappedDomNode = domConverter.mapViewToDom(viewElement);
        const rootRect = new Rect(mappedDomNode);

        return new PageBreakInfo({
            type: 'element',
            modelRange,
            domNode: mappedDomNode,
            offset: Math.round(heightOffset) ? heightOffset + domRect.top - rootRect.top : 0
        });
    }

    get configuredPaddings() {
        const pageMargins = this.editor.config.get('pagination.pageMargins') || {};
        return {
            top: '10mm',
            bottom: '10mm',
            left: '10mm',
            right: '10mm',
            ...pageMargins
        };
    }

    static findPageStarterInfos(container, start, columnWidth, increment) {
        return findPageStarterInfos(container, start, columnWidth, increment);
    }
}
