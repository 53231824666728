import { first as firstElement } from 'ckeditor5';
import {LineView} from './line-view.js';

export class LinesRepository {
	constructor(editor) {
		this._visibleLines = new Set();
		this._hiddenLines = new Set();
		this._viewCollection = editor.ui.view.body;
	}

	destroy() {
		for (const line of this._hiddenLines) {
			line.destroy();
		}
	}

	cleanLines() {
		for (const line of this._visibleLines) {
			line.isVisible = false;
			this._hiddenLines.add(line);
		}

		this._visibleLines.clear();
	}

	showLine(left, top, width, pageNumber) {
		let line;

		if (this._hiddenLines.size) {
			line = firstElement(this._hiddenLines.values());
			this._hiddenLines.delete(line);
		} else {
			line = new LineView();
		}

		line.set({ pageNumber, left, top, width });

		line.isVisible = true;

		if (!this._viewCollection.has(line)) {
			this._viewCollection.add(line);
		}

		this._visibleLines.add(line);
	}
}

